import React from 'react';

import { useParams } from 'react-router-dom';
import { Card } from '@mui/material';
import ObservationsSection from './Observations';
import { MedicationsSection } from './MedicationsSection';
import EncountersSection from './Encounters';
import ConditionsSection from './Conditions';
import { SidebarNav } from '../../../ui/components/sidebar-nav';

const sidebarNavItems = [
  {
    title: 'Observations',
    href: 'clinical/observations',
  },
  {
    title: 'Medications',
    href: 'clinical/medications',
  },
  {
    title: 'Encounters',
    href: 'clinical/encounters',
  },
  {
    title: 'Conditions',
    href: 'clinical/conditions',
  },
];

interface ClinicalLayoutProps {
  children: React.ReactNode;
}

export function ClinicalLayout({ children }: ClinicalLayoutProps) {
  return (
    <Card className="mb-8">
      <div className="hidden space-y-4 p-8 md:block">
        <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
          <aside className="-mx-4 lg:w-1/8">
            {/* <h2 className="text-lg font-semibold px-4">Clinical</h2> */}
            <SidebarNav items={sidebarNavItems} />
          </aside>
          <div className="flex-1 overflow-auto">{children}</div>
        </div>
      </div>
    </Card>
  );
}

export default function ClinicalSection() {
  const params = useParams<{ '*': string }>();

  const section = params['*'] || 'clinical/observations';

  return (
    <ClinicalLayout>
      {section === 'clinical/observations' && <ObservationsSection />}
      {section === 'clinical/medications' && <MedicationsSection />}
      {section === 'clinical/encounters' && <EncountersSection />}
      {section === 'clinical/conditions' && <ConditionsSection />}
    </ClinicalLayout>
  );
}
