import { DataTableFilterSingle } from '@/modules/data-table/components/data-table-filter-single';
import DataTableProviderFilter from '@/modules/data-table/components/data-table-provider-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import AppTitle from '@/modules/layout/components/app-title';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import { TaskEditButton } from '@/modules/tasks/components/TaskEditButton';
import React, { FC, useState } from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  useGetIdentity,
  useStore,
} from 'react-admin';
import { get } from 'lodash';
import TabbedWrapper from '@/modules/ra-ui/components/tabbed-wrapper';
import { AssignTasksButton } from '@/modules/tasks/components/AssignTasksButton';
import { Badge } from '@/modules/ui/components/badge';

const Toolbar = ({ setSelectedTab, selectedTab }) => {
  const { identity } = useGetIdentity();

  if (!identity) return null;

  return (
    <div className="w-full">
      <TabbedWrapper
        options={[
          { value: 'false', title: 'Not Completed' },
          { value: 'true', title: 'Completed' },
          { value: '', title: 'All' },
        ]}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      >
        <DataTableToolbar
          showViewOptions={false}
          search={false}
          className="px-2"
        >
          <DataTableProviderFilter column="tenant_id[eq]" title="Provider" />
          <DataTableFilterSingle
            column="assigned_to_id[eq]"
            title="My Tasks"
            options={[
              { label: 'Yes', value: get(identity, 'id', '').toString() },
            ]}
          />
        </DataTableToolbar>
      </TabbedWrapper>
    </div>
  );
};

export const TaskList: FC = (props) => {
  const [hideSandboxPatients] = useStore('preferences.hideSandboxPatients');
  const [selectedTab, setSelectedTab] = useState('false');

  return (
    <>
      <AppTitle title="Tasks" />
      <List
        {...props}
        exporter={false}
        actions={
          <Toolbar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        }
        filter={{
          'complete[eq]': selectedTab !== '' ? selectedTab : undefined,
        }}
        queryOptions={{
          meta: {
            ...(hideSandboxPatients && {
              is_sandbox_user: false,
            }),
          },
        }}
      >
        <Datagrid
          rowClick={false}
          bulkActionButtons={
            <>
              <AssignTasksButton />
            </>
          }
        >
          <TextField source="title" />
          <TextField source="description" />
          <BadgeField source="task_type" />
          <FunctionField
            source="priority"
            render={(record) => {
              const priority = record.priority;
              const priorityMap = {
                1: { label: 'High', color: 'red' },
                2: { label: 'Medium', color: 'yellow' },
                3: { label: 'Low', color: 'green' },
              };
              const { label, color } = priorityMap[priority] || {
                label: priority,
                color: 'gray',
              };
              if (!label) return null;
              return (
                <Badge
                  variant="outline"
                  className={`bg-${color}-100 text-${color}-800 border-${color}-300`}
                >
                  {label}
                </Badge>
              );
            }}
          />
          <ReferenceField
            label="Patient"
            source="patient_id"
            reference="patients"
            link="show"
          >
            <TextField source="user.full_name" />
          </ReferenceField>
          <TextField source="assigned_to_name" label="Assigned To" />
          <BooleanField source="complete" />
          <DateField source="created_on" />
          {selectedTab === 'true' ? (
            <DateField source="completed_at" />
          ) : (
            <DateField source="due_on" />
          )}
          <TaskEditButton />
        </Datagrid>
      </List>
    </>
  );
};
