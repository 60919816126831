import React from 'react';

import { RecordContextProvider, useGetIdentity, useGetOne } from 'react-admin';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@/modules/ui/components/card';
import { Badge } from '@/modules/ui/components/badge';

import { TwoFactorSetup } from '@/modules/account/components/TwoFactorSetup';
import { DateTime } from 'luxon';
import { ChangePassword } from '@/modules/account/components/ChangePassword';
import { NotificationSettings } from '@/modules/account/components/NotificationSettings';
import inflection from 'inflection';
import { AuthLogPanel } from '@/modules/users/components/AuthLogPanel';

export const Account = () => {
  const { identity } = useGetIdentity();
  const { data } = useGetOne(
    'users',
    { id: identity?.id },
    {
      enabled: !!identity?.id,
    },
  );

  return (
    <RecordContextProvider value={data}>
      <div className="flex flex-col gap-4 items-center p-4 mx-auto max-w-lg w-full">
        <Card className="w-full">
          <CardHeader>
            <CardTitle className="text-2xl">Account Details</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col gap-1">
              <CardDescription>
                <strong>Email:</strong> {identity?.email}
              </CardDescription>
              <CardDescription>
                <strong>Full Name:</strong> {identity?.fullName}
              </CardDescription>
              <CardDescription>
                <strong>Role:</strong>{' '}
                <Badge variant="outline">
                  {identity?.role ? inflection.titleize(identity.role) : null}
                </Badge>
              </CardDescription>
              <CardDescription>
                <strong>Last login:</strong>{' '}
                {data?.last_login
                  ? DateTime.fromISO(data?.last_login, {
                      zone: 'UTC',
                    })
                      .toLocal()
                      .toLocaleString(DateTime.DATETIME_FULL)
                  : 'Never'}
              </CardDescription>
            </div>
          </CardContent>
        </Card>
        <NotificationSettings />
        <TwoFactorSetup />
        <ChangePassword />
        <AuthLogPanel userId={identity?.id} />
      </div>
    </RecordContextProvider>
  );
};
