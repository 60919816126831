import { Chip, Typography } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { get } from 'lodash';

export const NameField = (props) => {
  const record = useRecordContext(props);

  if (!record) {
    return null;
  }

  const age = get(record, 'patient.profile.demographics.age');
  const gender = get(record, 'gender');

  return (
    <>
      <Typography variant="subtitle1" component="span">
        {get(record, 'user.full_name')}
      </Typography>
      {age && gender ? (
        <Typography variant="body2" component="span" sx={{ mx: 1 }}>
          {`(${age}, ${gender})`}{' '}
        </Typography>
      ) : null}

      {get(record, 'is_sandbox_user') ? (
        <Chip label="Sandbox" size="small" />
      ) : null}
    </>
  );
};
