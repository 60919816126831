import AppTitle from '@/modules/layout/components/app-title';
import { DataTableFilter } from '@/modules/data-table/components/data-table-filter';
import DataTableProviderFilter from '@/modules/data-table/components/data-table-provider-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import { BulkAddToCareTeam } from '@/modules/patients/components/bulk-add-to-care-team';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import { CreateButton } from '@/modules/ra-ui/components/create-button';
import { get } from 'lodash';
import React, { FC, Fragment } from 'react';
import {
  ArrayField,
  DatagridConfigurable,
  FunctionField,
  List,
  ReferenceField,
  SingleFieldList,
  TextField,
  usePermissions,
  useStore,
} from 'react-admin';
import { BulkAssignToProviderButton } from '@/modules/ra-ui/components/BulkAssignToProviderButton';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import { primaryConditions } from '@/modules/patients/constants/primaryConditions';
import { patientStatus } from '@/modules/patients/constants/patientStatus';
import TabbedWrapper from '@/modules/ra-ui/components/tabbed-wrapper';
import DataTableReferenceFilter from '@/modules/data-table/components/data-table-reference-filter';
import { CareProgramDataTableFilter } from '@/modules/patients/components/CareProgramDataTableFilter';

function calcAge(dateString) {
  var birthday = +new Date(dateString);
  return ~~((Date.now() - birthday) / 31557600000);
}

const Toolbar = ({ setSelectedTab, selectedTab }) => {
  return (
    <div className="w-full">
      <TabbedWrapper
        options={[
          ...patientStatus.map((status) => ({
            title: status.name,
            value: status.id,
          })),
          {
            title: 'All',
            value: '',
          },
        ]}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      >
        <DataTableToolbar
          showViewOptions={true}
          createButton={<CreateButton label="Patient" size="sm" />}
        >
          <DataTableProviderFilter column="tenant_id[eq]" title="Provider" />

          <DataTableFilter
            column="care_setting[in]"
            title="Care Setting"
            options={[
              { label: 'Home', value: 'home' },
              {
                label: 'Residential Care Facility',
                value: 'residential_care_facility',
              },
            ]}
          />
          <DataTableFilter
            column="primary_condition[in]"
            title="Primary Condition"
            options={[
              { label: 'Asthma', value: 'Asthma' },
              { label: 'COPD', value: 'COPD' },
              { label: 'Diabetes', value: 'Diabetes' },
              { label: 'General', value: 'general' },
              { label: 'Heart Failure', value: 'HeartFailure' },
              {
                label: 'Chronic Kidney Disease',
                value: 'chronic_kidney_disease',
              },
            ]}
          />
          <DataTableReferenceFilter
            resource="users"
            column="billing_provider_id[eq]"
            title="Billing Provider"
            optionText="full_name"
            customFilter={(name) => ({
              ...(name &&
                name.length > 1 && {
                  q: { field: 'user.full_name', value: name },
                }),
              'is_clinician[eq]': true,
            })}
          />
          <CareProgramDataTableFilter />
        </DataTableToolbar>
      </TabbedWrapper>
    </div>
  );
};

const PatientBulkActionButtons = () => (
  <Fragment>
    <BulkAssignToProviderButton resource="users" />
    <BulkAddToCareTeam />
  </Fragment>
);

export const PatientList: FC = (props) => {
  const { permissions } = usePermissions();
  const [hideSandboxPatients] = useStore('preferences.hideSandboxPatients');
  const [selectedTab, setSelectedTab] = useStore(
    'patients.listParams.patientStatus',
    'active',
  );

  return (
    <>
      <AppTitle title="Patients" />
      <List
        {...props}
        actions={
          <Toolbar setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
        }
        exporter={false}
        filter={{
          ...(hideSandboxPatients && {
            'is_sandbox_user[eq]': false,
          }),
          ...(selectedTab !== '' && { 'patient_status[eq]': selectedTab }),
        }}
        empty={false}
        sort={{ field: 'user.registered_on', order: 'DESC' }}
      >
        <DatagridConfigurable
          rowClick="show"
          bulkActionButtons={<PatientBulkActionButtons />}
          omit={['patient.profile.demographics.age', 'gender']}
        >
          <BadgeField
            source="patient_status"
            label="Status"
            mapping={patientStatus}
          />
          <TextField
            source="user.full_name"
            label="Name"
            sortBy="user.last_name"
          />
          <TextField source="user.email" label="Email" />
          <TextField source="gender" label="Sex at Birth" />

          <FunctionField
            source="birthdate"
            label="Age"
            render={(record) =>
              record.birthdate ? `${calcAge(record.birthdate)}Y` : ''
            }
          />

          <BadgeField
            source="primary_condition"
            mapping={primaryConditions}
            applyMappedClassName={false}
            humanize={false}
          />

          {selectedTab === 'active' ? (
            <LuxonDateField source="user.enrolled_on" label="Enrolled On" />
          ) : (
            <LuxonDateField source="user.registered_on" label="Registered On" />
          )}
          <ReferenceField
            label="Billing Provider"
            source="billing_provider_id"
            reference="users"
            link="show"
            emptyText="Not set"
          >
            <TextField source="full_name" />
          </ReferenceField>
          {get(permissions, 'is_internal') === true ? (
            <ReferenceField
              label="Provider"
              source="user.tenant_id"
              reference="providers"
              link="show"
              emptyText="None"
            >
              <TextField source="name" />
            </ReferenceField>
          ) : null}

          <ArrayField
            source="service_instances"
            sortable={false}
            label="Care Programs"
          >
            <SingleFieldList linkType={false}>
              <BadgeField
                source="type"
                humanize={false}
                capitalize={true}
                className="m-0.5"
              />
            </SingleFieldList>
          </ArrayField>
        </DatagridConfigurable>
      </List>
    </>
  );
};
