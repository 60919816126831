import React, { FC } from 'react';
import {
  CLOSED_DRAWER_WIDTH,
  DRAWER_WIDTH,
  DatagridConfigurable,
  List,
  useSidebarState,
  useStore,
} from 'react-admin';

import { DataTableFilter } from '@/modules/data-table/components/data-table-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import { primaryConditions } from '@/modules/patients/constants/primaryConditions';

import ProviderField from '@/modules/ra-ui/components/ProviderField';
import AppTitle from '@/modules/layout/components/app-title';
import DataTableProviderFilter from '@/modules/data-table/components/data-table-provider-filter';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import { Toggle } from '@/modules/ui/components/toggle';
import { patientReviewStatus } from '@/modules/patients/constants/patientReviewStatus';
import { patientPriorityLevel } from '@/modules/patients/constants/patientPriorityLevel';
import clsx from 'clsx';
import { Clipboard, ClipboardCheck } from 'lucide-react';
import { DataTableDaysFilter } from '@/modules/data-table/components/data-table-days-filter';
import { PatientQueueEmpty } from '@/modules/patient-queue/components/PatientQueueEmpty';
import { NameField } from '../../modules/patients/components/NameField';
import { LatestMeasurementField } from '../../modules/patient-queue/components/LatestMeasurementField';
import { Separator } from '@/modules/ui/components/separator';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/modules/ui/components/select';

const BUFFER = 28;

const Toolbar = ({ view }) => (
  <DataTableToolbar showViewOptions={true}>
    <DataTableProviderFilter column="tenant_id[eq]" title="Provider" />
    {view === 'general' ? null : (
      <DataTableFilter
        column={`${view}_review_status[in]`}
        title="Review Status"
        options={[
          { label: 'Pending', value: 'pending', icon: Clipboard },
          {
            label: 'Reviewed',
            value: 'reviewed',
            icon: ClipboardCheck,
          },
        ]}
      />
    )}
    <DataTableFilter
      column="care_setting[in]"
      title="Care Setting"
      options={[
        { label: 'Home', value: 'home' },
        {
          label: 'Residential Care Facility',
          value: 'residential_care_facility',
        },
      ]}
    />
    <DataTableFilter
      column="primary_condition[in]"
      title="Primary Condition"
      options={[
        { label: 'Asthma', value: 'Asthma' },
        { label: 'COPD', value: 'COPD' },
        { label: 'Diabetes', value: 'Diabetes' },
        { label: 'General', value: 'general' },
        { label: 'Heart Failure', value: 'HeartFailure' },
        { label: 'Chronic Kidney Disease', value: 'chronic_kidney_disease' },
      ]}
    />
    <DataTableDaysFilter
      column="last_device_activity[lte]"
      title="Days since Measurement"
    />
  </DataTableToolbar>
);

const PatientQueueTable = () => {
  const [hideSandboxPatients] = useStore('preferences.hideSandboxPatients');
  const [open] = useSidebarState();
  const [underCare, setUnderCare] = useStore(
    'patient-queue.list.under_care',
    true,
  );
  const [view, setView] = useStore('patient-queue.list.viewType', 'general');

  return (
    <div
      className={`max-w-[calc(100vw-${open ? DRAWER_WIDTH + BUFFER : CLOSED_DRAWER_WIDTH + BUFFER}px)]`}
    >
      <div className="flex items-center mb-2 gap-2">
        <Toggle
          variant="outline"
          aria-label="Toggle under care"
          onClick={() => setUnderCare(!underCare)}
          size="lg"
          className={clsx(
            'h-8 bg-white',
            underCare ? 'border-primary' : 'bg-white',
          )}
          defaultChecked={underCare}
        >
          {underCare ? 'Showing Patients in my Care' : 'Showing all Patients'}
        </Toggle>
        <Separator orientation="vertical" className="h-8" />
        <Select value={view} onValueChange={setView}>
          <SelectTrigger className="w-fit h-8">
            <SelectValue placeholder="Select view" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="general">General</SelectItem>
            <SelectItem value="rpm">RPM</SelectItem>
            <SelectItem value="ccm">CCM</SelectItem>
            <SelectItem value="pcm">PCM</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <AppTitle title="Patient Queue" />
      <List
        actions={<Toolbar view={view} />}
        title="Patient Queue"
        resource="patient-queue"
        sort={{ field: 'priority', order: 'DESC' }}
        filter={{
          ...(hideSandboxPatients && {
            'is_sandbox_user[eq]': false,
          }),
          'patient_status[eq]': 'active',
          ...(view !== 'general' && {
            [`has_${view}[eq]`]: true,
          }),
        }}
        exporter={false}
        queryOptions={{
          meta: {
            expand: ['profile', 'observations'],
            under_care: underCare,
          },
        }}
        sx={{
          width: '100%',
          '& .RaList-main': {
            width: '100%',
          },
        }}
        empty={<PatientQueueEmpty underCare={underCare} />}
      >
        <DatagridConfigurable
          preferenceKey={'patient-queue.datagrid.' + view}
          isRowSelectable={() => false}
          bulkActionButtons={false}
          rowClick="show"
          omit={[
            'blood_glucose',
            'peak_expiratory_flow_rate',
            'forced_expiratory_volume_1',
          ]}
          sx={{
            '& .RaDatagrid-root': {
              width: '100%',
            },
            '& .RaDatagrid-tableWrapper': {
              overflowX: 'scroll',
            },
            '& .RaDatagrid-table': {
              minWidth: '2000',
            },
            '& .RaDatagrid-headerCell': {
              whiteSpace: 'nowrap',
            },
            '& .RaDatagrid-rowCell': {
              whiteSpace: 'nowrap',
            },
          }}
        >
          <BadgeField
            source="priority"
            label="Priority"
            mapping={patientPriorityLevel}
            humanize={false}
          />
          <NameField source="user.last_name" label="Patient" />
          <LuxonDateField
            source="last_device_activity"
            label="Latest measurement"
            showTime={true}
          />
          <LatestMeasurementField
            source="oxygen_saturation"
            sortable={false}
            label="Oxygen saturation (%)"
          />
          <LatestMeasurementField
            source="heart_rate"
            sortable={false}
            label="Heart rate (bpm)"
          />
          <LatestMeasurementField
            source="blood_pressure"
            sortable={false}
            label="Blood pressure (mmHg)"
          />
          <LatestMeasurementField
            source="body_mass"
            sortable={false}
            label="Weight (lb)"
          />
          <LatestMeasurementField
            source="blood_glucose"
            sortable={false}
            label="Blood glucose (mg/dL)"
          />
          <LatestMeasurementField
            source="peak_expiratory_flow_rate"
            sortable={false}
            label="Peak expiratory flow rate (L/min)"
          />
          <LatestMeasurementField
            source="forced_expiratory_volume_1"
            sortable={false}
            label="FEV1 (L)"
          />
          <BadgeField
            source="primary_condition"
            mapping={primaryConditions}
            applyMappedClassName={false}
            humanize={false}
          />
          <ProviderField source="user.tenant_id" label="Provider" />
          {view === 'general' ? null : (
            <LuxonDateField
              source={`${view}_last_reviewed`}
              label="Last Reviewed"
              showTime={true}
              emptyText="Never"
            />
          )}
          {view === 'general' ? null : (
            <BadgeField
              label="Review Status"
              source={`${view}_review_status`}
              mapping={patientReviewStatus}
            />
          )}
        </DatagridConfigurable>
      </List>
    </div>
  );
};

export const PatientQueue: FC = () => {
  return (
    <div className="pb-2">
      <PatientQueueTable />
    </div>
  );
};
