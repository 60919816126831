import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';

import { Button } from '@/modules/ui/components/button';
import { Eye, Loader2 } from 'lucide-react';
import { useDataProvider, useRecordContext } from 'react-admin';
import { useQuery } from 'react-query';
import inflection from 'inflection';
import { Badge } from '@/modules/ui/components/badge';
import { dateFormatter } from '@/utils/formatedData';
import { DateTime } from 'luxon';
import {
  get,
  find,
  flatMap,
  map,
  isEmpty,
  isObject,
  some,
  isArray,
} from 'lodash';
import { triageText } from '@/modules/patients/assessment-summary/constants/triageText';
import Markdown from 'react-markdown';

export const AssessmentReview = () => {
  const record = useRecordContext();

  const [open, setOpen] = React.useState(false);
  const dataProvider = useDataProvider();

  const { data, isLoading } = useQuery(
    ['getAssessment', record?.patientId, record?.id],
    () =>
      dataProvider
        .getCustom(`patients/${record?.patientId}/runs/${record.id}`, {})
        .then((response) => {
          return response.data;
        }),
    { enabled: !!record && open },
  );

  let title = get(data, 'questionnaire.title') || get(data, 'run.name');

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="ghost">
        <Eye className="mr-2 h-4 w-4 " />
        View
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent
          onClose={() => setOpen(false)}
          className="max-h-[80vh] overflow-y-auto"
        >
          {isLoading && <Loader2 />}
          {!isLoading && open && (
            <>
              <DialogHeader className="mb-2">
                <DialogTitle className="mr-10">{title}</DialogTitle>
                <div className="flex flex-row gap-1 justify-between">
                  {
                    <Badge className="w-fit">
                      {inflection.humanize(get(data, 'run.category', ''))}
                    </Badge>
                  }
                  <p>
                    {dateFormatter(
                      get(data, 'run.created_at', ''),
                      DateTime.DATETIME_MED,
                    )}
                  </p>
                </div>
              </DialogHeader>
              {get(data, 'run.category') === 'survey' && (
                <SurveyView data={data} />
              )}
              {(get(data, 'run.category') === 'prediction' ||
                get(data, 'run.category') === 'disease_assessment') && (
                <PredictionView data={data} />
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const SurveyView = ({ data }) => {
  const answerItem = (subItem) => {
    const answerTemp = find(
      flatMap(data.questionnaire_response.items, (item) =>
        item.question === item.linkId ? [item] : item.value,
      ),
      { question: subItem.linkId },
    );

    return get(answerTemp, 'value.text');
  };

  return (
    <>
      {data?.questionnaire?.items?.map((item) => {
        const subItemsContent = item.subItems
          .map((subItem) => {
            const answer = answerItem(subItem);
            if (!answer) return null;
            return (
              <div
                key={subItem.linkId}
                className="border-t-2 border-gray-200  mb-2"
              >
                <h3 className="text-md ">
                  <Markdown>{subItem.text}</Markdown>
                </h3>
                <p className="pl-4 font-semibold">{answer}</p>
              </div>
            );
          })
          .filter(Boolean); // Filter out null values to ensure no blank items

        if (subItemsContent.length === 0) return null; // Ensure we don't render a container for items without content

        return (
          <div key={item.linkId} className="mb-2">
            {subItemsContent}
          </div>
        );
      })}
      {get(data, 'run.category') === 'survey' &&
        !isEmpty(get(data, 'run.predictions')) && (
          <div className="mt-2 border-2 border-gray-200 p-4 rounded-md">
            {map(get(data, 'run.predictions[0].response', {}), (value, key) => (
              <p key={key}>
                {inflection.humanize(key)}:{' '}
                <span className="font-semibold">{JSON.stringify(value)}</span>
              </p>
            ))}
          </div>
        )}
    </>
  );
};

const PredictionView = ({ data }) => {
  const findAnswer = (subItem) => {
    const answer = find(
      flatMap(data.questionnaire_response.items, (item) =>
        item.question === item.linkId ? [item] : item.value,
      ),
      { question: subItem.linkId },
    );

    if (!answer) {
      return null;
    }

    // if value is an array, then list out all the texts
    if (answer.type === 'choice') {
      if (isArray(answer.value) && answer.value.length > 0) {
        return map(answer.value, (item) => get(item, 'text')).join(', ');
      }
      return get(answer, 'value.text', '');
    }

    if (answer.type === 'integer') {
      // if both unit and value is present, then let's format it
      let unit = get(answer, 'value.units.text');
      let value = get(answer, 'value.value') || get(answer, 'value');

      return unit ? `${value} ${unit}` : value;
    }

    return get(answer, 'value.text');
  };

  return (
    <>
      {data?.questionnaire?.items?.map((item, index) => {
        const subItemsContent = item.subItems
          .map((subItem, index_sub) => {
            const answer = findAnswer(subItem);

            if (answer === null || typeof answer === 'object') {
              return null;
            }

            return (
              <div
                key={index_sub}
                className="border-t-2 border-gray-200 mb-2 last:mb-0"
              >
                <h3 className="text-md ">
                  <Markdown>
                    {item.text
                      ? `${item.text} - ${subItem.text}`
                      : subItem.text}
                  </Markdown>
                </h3>
                <p className="pl-4 font-semibold">{answer}</p>
              </div>
            );
          })
          .filter(Boolean);

        if (subItemsContent.length === 0) {
          return null;
        }

        return (
          <div key={index} className="mb-2">
            {subItemsContent}
          </div>
        );
      })}
      {get(data, 'run.category') === 'prediction' &&
        !isEmpty(get(data, 'run.predictions')) &&
        (() => {
          const responses = get(data, 'run.predictions[0].response', {});
          const hasNonEmptyResponses = some(
            responses,
            (response) => !isEmpty(response),
          );
          if (!hasNonEmptyResponses) return null;

          return (
            <div className="mt-2 border-2 border-gray-200 p-4 rounded-md">
              {map(responses, (value, key) => (
                <div key={key} className="pb-2 border-b-2 border-gray-200">
                  <h4 className="font-semibold">{inflection.humanize(key)}</h4>
                  {isObject(value) && key !== 'triage' ? (
                    <div className="flex flex-col">
                      <div className="flex items-center my-2">
                        <div
                          style={{
                            backgroundColor: get(value, 'donutColor'),
                          }}
                          className="w-6 h-6 rounded-full mr-2"
                        ></div>
                        <p className="font-bold">
                          {Math.round(get(value, 'value', 0))}
                        </p>
                        <p className="text-sm ml-2">
                          {get(value, 'donutText', '')}
                        </p>
                      </div>
                    </div>
                  ) : null}
                  {key === 'triage' && (
                    <div className="flex flex-row gap-2 items-center justify-start">
                      <Badge variant="outline" className="w-fit">
                        {get(triageText, get(value, 'key', ''), '')}
                      </Badge>
                      <p className="text-sm italic">{get(value, 'text', '')}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          );
        })()}
    </>
  );
};
