import { roundTo } from '@/modules/metrics/utils/roundTo';
import { Button } from '@/modules/ui/components/button';
import { Calendar } from '@/modules/ui/components/calendar';
import { Checkbox } from '@/modules/ui/components/checkbox';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/modules/ui/components/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/modules/ui/components/select';
import { Separator } from '@/modules/ui/components/separator';
import { cn } from '@/modules/ui/utils/cn';
import { format } from 'date-fns';
import { get, first } from 'lodash';
import { CalendarIcon } from 'lucide-react';
import { DateTime } from 'luxon';
import React from 'react';
import { chartProperties } from './chartProperties';
import { useRecordContext } from 'react-admin';

export function MetricsSummarySidebar({
  data,
  showTrend,
  setShowTrend,
  range,
  setRange,
  timescale,
  setTimescale,
}) {
  const chartName = get(data, 'chart_name');
  const mostRecent = first(get(data, 'points', []));

  const patientRecord = useRecordContext();

  const referenceValues = {
    forced_expiratory_volume_1: 'fvc_GLI2012',
    peak_expiratory_flow_rate: 'pef_NHANES',
  };
  let percentPredicted;

  const chartReferenceKey = referenceValues[chartName];
  if (
    chartReferenceKey &&
    get(
      patientRecord,
      `patient.profile.reference_values.${chartReferenceKey}.value`,
    )
  ) {
    const value = mostRecent.y;
    const referenceValue = get(
      patientRecord,
      `patient.profile.reference_values.${chartReferenceKey}.value`,
    );
    percentPredicted = (value / referenceValue) * 100;
  }

  const setStartDate = (date: Date) => {
    setRange([date, get(range, 1)]);
  };

  const setEndDate = (date: Date) => {
    setRange([get(range, 0), date]);
  };

  return (
    <div className="flex flex-col gap-2">
      <div>
        <p className="text-xl font-medium mb-2">
          {get(chartProperties, [chartName, 'name'])}{' '}
          <span className="text-sm text-gray-500 uppercase">
            {get(data, 'unit.name')}
          </span>
        </p>
        {mostRecent ? (
          <>
            <p className="text-sm font-medium">Most recent</p>
            {chartName === 'blood_pressure' ? (
              <p className="text-xl font-medium">
                {roundTo(
                  get(mostRecent, 'y'),
                  get(chartProperties, [chartName, 'round'], 0),
                )}
                {'/'}
                {roundTo(
                  get(mostRecent, 'y2'),
                  get(chartProperties, [chartName, 'round'], 0),
                )}
              </p>
            ) : (
              <p className="text-xl font-medium">
                {roundTo(
                  get(mostRecent, 'y'),
                  get(chartProperties, [chartName, 'round'], 0),
                )}
                <span className="text-sm ml-1 text-black-500">
                  {percentPredicted && `(${percentPredicted.toFixed(0)}%)`}
                </span>
              </p>
            )}
            <p className="text-md text-gray-500">
              {DateTime.fromISO(get(mostRecent, 'x'))
                .toLocal()
                .toLocaleString(DateTime.DATETIME_MED)}
            </p>
          </>
        ) : null}
      </div>
      <Separator />
      <div>
        <p className="text-sm font-medium">Data Range</p>
        <p className="text-md">
          {`${DateTime.fromISO(get(data, 'details.data_range[0]'))
            .toLocal()
            .toLocaleString(DateTime.DATE_MED)} to 
            ${DateTime.fromISO(get(data, 'details.data_range[1]'))
              .toLocal()
              .toLocaleString(DateTime.DATE_MED)}`}
        </p>
        <p className="text-md">
          {get(data, 'details.data_points')} total measurements
        </p>
      </div>
      <Separator />
      <p className="text-sm font-medium mb-1">Properties</p>
      <div className="flex flex-col gap-2">
        <div>
          <p className="text-sm text-gray-500">Chart timescale</p>
          <Select
            value={timescale}
            onValueChange={(value) => setTimescale(value)}
          >
            <SelectTrigger className="w-[150px] h-[30px]">
              <SelectValue placeholder="Timescale" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All</SelectItem>
              <SelectItem value="day">Day</SelectItem>
              <SelectItem value="week">Week</SelectItem>
              <SelectItem value="month">Month</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm text-gray-500">Data range</p>
          <DatePicker date={range[0]} setDate={setStartDate} label="Start" />
          <DatePicker date={range[1]} setDate={setEndDate} label="End" />
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm text-gray-500">Misc</p>
          <div className="items-top flex space-x-2">
            <Checkbox
              id="terms1"
              checked={showTrend}
              onCheckedChange={(value) => setShowTrend(value)}
            />
            <div className="grid gap-1.5 leading-none">
              <label
                htmlFor="terms1"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Show trend lines
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const DatePicker = ({ date, setDate, label }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'w-[200px] justify-start text-left font-normal',
            !date && 'text-muted-foreground',
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, 'PPP') : <span>{label}</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};
