import { ColoredChipField } from '@/modules/ra-ui/components/ColoredChipField';
import AppTitle from '@/modules/layout/components/app-title';
import React, { FC } from 'react';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';

export const SupportTicketList: FC = (props) => (
  <>
    <AppTitle title="Support Tickets" />
    <List {...props}>
      <Datagrid rowClick="show">
        <TextField source="category" />
        <ReferenceField
          label="By"
          source="user_id"
          reference="patients"
          link="show"
        >
          <TextField source="user.full_name" />
        </ReferenceField>
        <DateField source="created_on" />
        <ColoredChipField source="status" />
      </Datagrid>
    </List>
  </>
);
