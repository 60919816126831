import { cn } from '@/modules/ui/utils/cn';
import React from 'react';

type Props = {
  title: string;
  text?: string;
  width?: string;
  component?: any;
};

export const InfoBlock: React.FC<Props> = ({ title, text, component }) => {
  return (
    <div className={cn('text-wrap break-words', 'min-w-[150px]  xl:w-[200px]')}>
      <div>
        <div className="mb-1">
          <p className="text-sm">{title}</p>
        </div>
        {text ? (
          <p className="text-base font-medium overflow-auto whitespace-normal text-wrap break-words	">
            {text}
          </p>
        ) : null}
        {component ? component : null}
      </div>
    </div>
  );
};
