import React from 'react';

import { useRecordContext } from 'react-admin';

import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import { Button } from '@/modules/ui/components/button';
import { Trash2 } from 'lucide-react';
import { BooleanInput } from 'react-admin';

export const MedicationDeleteButton = ({
  variant = 'default',
  label = 'Delete Medication',
}) => {
  const record = useRecordContext();

  const transform = (data) => {
    return {
      ...data,
      status: 'stopped',
      update_reason: 'status-change',
    };
  };

  return (
    <EditInDialogButton
      title="Delete medication"
      resource={`patients/${record?.patient_id}/medication-administrations`}
      transform={transform}
      variant={variant}
      label={label}
      notifyMessage="Medication deleted"
      refreshOnSuccess={true}
      editButton={
        <Button size="sm" className="h-8" variant="ghost">
          <Trash2 className="mr-2 h-4 w-4 fill-red-500 stroke-red-500" />
        </Button>
      }
    >
      <BooleanInput
        source="confirm_delete"
        label="Confirm delete"
        validate={[
          (value, allValues) => {
            if (value === true) {
              return undefined;
            }
            return 'Please confirm deletion.';
          },
        ]}
      />
    </EditInDialogButton>
  );
};
