import ProviderField from '@/modules/ra-ui/components/ProviderField';
import AppTitle from '@/modules/layout/components/app-title';
import DataTableProviderFilter from '@/modules/data-table/components/data-table-provider-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import { AddressField } from '@/modules/ra-ui/components/address-field';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { Building } from 'lucide-react';
import React, { FC } from 'react';
import { DatagridConfigurable, List, TextField } from 'react-admin';
import { LocationCreateButton } from '../../modules/locations/components/LocationCreateButton';

const Toolbar = () => (
  <DataTableToolbar
    showViewOptions={false}
    createButton={<LocationCreateButton />}
    search={false}
  >
    <DataTableProviderFilter column="tenant_id[eq]" title="Provider" />
  </DataTableToolbar>
);

export const LocationList: FC = (props) => {
  return (
    <>
      <AppTitle title="Locations" />
      <List
        {...props}
        actions={<Toolbar />}
        storeKey={false}
        sort={{
          field: null,
          order: null,
        }}
        empty={
          <EmptyDatagrid
            resourceName="Locations"
            description="Locations are the physical locations where services are provided and devices stored."
            classNameEmpty="w-full"
            icon={<Building />}
            actionButton={<LocationCreateButton />}
          />
        }
      >
        <DatagridConfigurable
          rowClick="show"
          omit={['end_date_of_service']}
          bulkActionButtons={false}
        >
          <TextField source="name" />
          <AddressField source="address" />
          <ProviderField label="Provider" />
        </DatagridConfigurable>
      </List>
    </>
  );
};
