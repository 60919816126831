import { Badge } from '@/modules/ui/components/badge';
import { Card } from '@/modules/ui/components/card';
import { cn } from '@/modules/ui/utils/cn';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import inflection from 'inflection';
import { get, find } from 'lodash';
import React from 'react';
import {
  EditButton,
  FunctionField,
  Button as RAButton,
  ReferenceField,
  usePermissions,
  useRecordContext,
  Link,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { ColoredChipField } from '../../ra-ui/components/ColoredChipField';
import { monitoringConditionColor } from '../../../utils/color';
import { conditionNames } from '../constants/conditionNames';
import { dateFormatter } from '../../../utils/formatedData';
import { InfoBlock } from './InfoBlock';
import { UnenrollPatientDialog } from './UnenrollPatientDialog';

import AppTitle from '@/modules/layout/components/app-title';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/modules/ui/components/dropdown-menu';
import { MoreVertical } from 'lucide-react';
import { MonitoringActivityCreateButton } from '../../monitoring-activity/components/MonitoringActivityCreate';
import { PatientStatusDialog } from './PatientStatusDialog';
import { Skeleton } from '@/modules/ui/components/skeleton';
import DiagnosisBadge from './DiagnosisBadge';
import { PatientNotes } from '@/modules/patients/components/PatientNotes';
import { usePatientStatus } from '../hooks/patient-status';
import { patientStatus as patientStatusList } from '../constants/patientStatus';

const PatientToolbarDropDown = () => {
  const [openPatientStatusDialog, setOpenPatientStatusDialog] =
    React.useState(false);
  const [openUnenrollPatientDialog, setOpenUnenrollPatientDialog] =
    React.useState(false);

  const record = useRecordContext();
  return (
    <>
      <PatientStatusDialog
        open={openPatientStatusDialog}
        setOpen={setOpenPatientStatusDialog}
      />
      <UnenrollPatientDialog
        open={openUnenrollPatientDialog}
        setOpen={setOpenUnenrollPatientDialog}
      />
      <DropdownMenu>
        <DropdownMenuTrigger>
          <MoreVertical />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="flex flex-col" align="end">
          <DropdownMenuItem onClick={() => setOpenPatientStatusDialog(true)}>
            Set Patient Status
          </DropdownMenuItem>
          {record.patient_status === 'disabled' ? null : (
            <DropdownMenuItem
              onClick={() => setOpenUnenrollPatientDialog(true)}
              className="bg-destructive text-destructive-foreground focus:bg-destructive/90 focus:text-destructive-foreground"
            >
              Unenroll Patient
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

const PatientStatusIndicator = () => {
  const patientStatus = usePatientStatus();
  const patientStatusValue = find(patientStatusList, ['id', patientStatus]);

  return (
    <Badge
      variant="secondary"
      className={cn(
        'text-md',
        'pointer-events-none',
        'whitespace-nowrap	',
        patientStatusValue?.className,
      )}
    >
      {inflection.titleize(patientStatusValue?.name || patientStatus)}
    </Badge>
  );
};

const NameToolbar = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();
  const location = useLocation();
  const patientStatus = usePatientStatus();

  return (
    <div className="flex items-center justify-between mb-2">
      <div className="flex items-center">
        <div className="mr-4">
          <h1 className="text-4xl font-semibold">
            {get(record, 'user.full_name')}
          </h1>
        </div>
        <PatientStatusIndicator />
        {get(record, 'is_sandbox_user') && (
          <Badge
            variant="secondary"
            className="text-md ml-2 border-2 border-black-500"
          >
            Sandbox
          </Badge>
        )}
      </div>
      <div className="flex flex-row gap-2">
        {location.pathname.includes('queue') ? (
          <MonitoringActivityCreateButton tenant_id={record?.user?.tenant_id} />
        ) : null}
        {location.pathname.includes('queue') ? (
          <Link to={`/patients/${record.id}/show`}>
            <RAButton variant="text" label="Profile">
              <AccountBoxIcon />
            </RAButton>
          </Link>
        ) : get(permissions, 'patients.queue') &&
          !['pending_enrollment'].includes(patientStatus) ? (
          <Link to={`/patient-queue/${record.id}/show`}>
            <RAButton variant="text" label="Clinical">
              <MonitorHeartIcon />
            </RAButton>
          </Link>
        ) : null}

        <EditButton variant="text" label="Edit" />
        <PatientToolbarDropDown />
      </div>
    </div>
  );
};

export const PatientOverview = () => {
  const record = useRecordContext();
  const location = useLocation();

  const heightValue = get(record, 'patient.profile.demographics.height.value');
  const heightUnit = get(
    record,
    'patient.profile.demographics.height.unit.text',
  );
  const height = !heightValue
    ? 'N/A'
    : heightUnit === 'in'
      ? `${Math.floor(heightValue / 12)}' ${Math.round(heightValue % 12)}"`
      : `${Math.round(heightValue)} ${heightUnit}`;
  const weightValue = get(record, 'patient.profile.demographics.weight.value');
  const weightUnit = get(
    record,
    'patient.profile.demographics.weight.unit.text',
  );
  const weight = weightValue
    ? `${Math.round(weightValue)} ${weightUnit}`
    : 'N/A';

  if (!record) {
    return (
      <div className="flex flex-col gap-2">
        <Skeleton className="w-full sm:w-[80vw] md:w-[70vw] lg:w-[60vw] xl:w-[50vw] h-[50px]" />
        <Skeleton className="w-full sm:w-[80vw] md:w-[70vw] lg:w-[60vw] xl:w-[50vw] h-[170px]" />
      </div>
    );
  }

  return (
    <div className="mb-2 w-full">
      <AppTitle title={`Patient`} onlyHeader />
      <NameToolbar />
      <Card className="flex flex-wrap p-4">
        <div className="w-full lg:w-3/4 xl:w-4/5 ">
          <div className="mb-2 grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-3">
            <InfoBlock
              title="Monitoring Condition"
              component={
                <ColoredChipField
                  source="primary_condition"
                  colorScheme={monitoringConditionColor}
                  mapping={conditionNames}
                />
              }
            />

            <InfoBlock
              title="Age, Sex"
              text={`${get(
                record,
                'patient.profile.demographics.age',
                '',
              )}, ${get(record, 'patient.profile.demographics.gender', '')}`}
            />
            <InfoBlock
              title="Date of Birth"
              text={dateFormatter(get(record, 'birthdate'))}
            />

            <InfoBlock
              title="Enrolled on"
              text={dateFormatter(get(record, 'user.enrolled_on')) || 'Not set'}
            />

            <InfoBlock
              title="Phone Number"
              text={get(record, 'user.phone_number') || 'Not set'}
            />

            <InfoBlock
              title="Email"
              text={get(record, 'user.email') || 'Not set'}
            />
            <ReferenceField
              label="Provider"
              source="user.tenant_id"
              reference="providers"
              link="show"
            >
              <FunctionField
                render={(record) => (
                  <InfoBlock title="Provider" text={get(record, 'name', '')} />
                )}
              />
            </ReferenceField>
            <InfoBlock
              title="Care Setting"
              text={inflection.titleize(get(record, 'care_setting', ''))}
            />
            <InfoBlock title="Diagnoses" component={<DiagnosisBadge />} />

            <ReferenceField
              label="Provider"
              source="billing_provider_id"
              reference="users"
              link="show"
            >
              <FunctionField
                render={(record) => (
                  <InfoBlock
                    title="Primary Billing Provider"
                    text={get(record, 'full_name', '')}
                  />
                )}
              />
            </ReferenceField>

            {location.pathname.includes('queue') && (
              <>
                <InfoBlock title="Height" text={height} />
                <InfoBlock title="Weight" text={weight} />
                <InfoBlock
                  title="Smoking Status"
                  text={`${get(record, 'patient.profile.smoking') ?? 'N/A'}`}
                />
              </>
            )}
          </div>
        </div>
        <div className="w-full md:w-1/4 xl:w-1/5 ">
          <PatientNotes />
        </div>
      </Card>
    </div>
  );
};
