import React from 'react';
import { PatientMonitoringConfig } from '@/modules/patient-monitoring-config/components/PatientMonitoringConfig';
import { DevicesDataGrid } from '../../components/DevicesDataGrid';
import { useRecordContext } from 'ra-core';

export default function MonitoringSection(props) {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-2 my-4">
      <div className="flex flex-col space-y-2">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-xl font-semibold">Monitoring</h3>
            <p className="text-sm text-gray-500 max-w-[800px]">
              The following are devices linked to this patient. To modify
              patient devices, please visit the devices page.
            </p>
          </div>
        </div>
        <DevicesDataGrid />
      </div>
      <PatientMonitoringConfig />
    </div>
  );
}
