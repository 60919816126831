import { ReferenceField, TextField, usePermissions } from 'react-admin';
import React from 'react';
import { get } from 'lodash';

const ProviderField = ({
  source = 'tenant_id',
  label = 'Provider',
  emptyText = null,
}) => {
  const { permissions } = usePermissions();

  if (get(permissions, 'is_internal') === true) {
    return (
      <ReferenceField
        source={source}
        reference="providers"
        link="show"
        label={label}
        emptyText={emptyText}
      >
        <TextField source="name" />
      </ReferenceField>
    );
  }

  return null;
};

export default ProviderField;
