import {
  Bug,
  Building,
  Building2,
  Contact,
  FileQuestion,
  FileText,
  HelpCircle,
  Home,
  Hourglass,
  ListTodo,
  RadioTower,
  Ticket,
  UserPlus,
  Users,
} from 'lucide-react';

interface MenuConfig {
  name?: string;
  resources: Resource[];
}

interface Resource {
  name: string;
  icon: any;
  link: string;
  permissions?: string[] | null;
  roles?: string[] | null;
  section?: string;
  type?: string;
  internal_user_only?: boolean;
  dev_only?: boolean;
}

export const menuConfig: Array<MenuConfig> = [
  {
    resources: [
      {
        name: 'Home',
        icon: Home,
        link: '/',
      },
    ],
  },
  {
    name: 'Clinical',
    resources: [
      {
        name: 'Patient Queue',
        icon: Contact,
        link: 'patient-queue',
        permissions: ['patients.queue'],
      },
      {
        name: "Enrollment Queue",
        icon: UserPlus,
        link: 'enrollment',
        permissions: ['patients.list'],
        internal_user_only: true,
      },
      {
        name: "Monitoring",
        icon: Hourglass,
        link: 'monitoring',
        permissions: ['patients.list'],
        internal_user_only: true,
      },
      {
        name: 'Tasks',
        icon: ListTodo,
        link: 'tasks',
        permissions: ['tasks.list'],
      },
    ],
  },
  {
    name: 'Management',
    resources: [
      {
        name: 'Patients',
        icon: Contact,
        link: 'patients',
        permissions: ['patients.list'],
      },
      {
        name: 'Devices',
        icon: RadioTower,
        link: 'devices',
        permissions: ['devices.list'],
      },
      {
        name: 'Locations',
        icon: Building,
        link: 'locations',
        permissions: ['locations.list'],
        internal_user_only: true,
      },
      {
        name: 'Claims',
        icon: Ticket,
        link: 'claim-items',
        permissions: ['claim_items.list'],
      },
      {
        name: 'Reports',
        icon: FileText,
        link: 'reports',
        permissions: ['reports.list'],
        roles: ['superadmin', 'internal_admin', 'clinical_specialist'],
      },

      {
        name: 'Users',
        icon: Users,
        link: 'users',
        permissions: ['users.list', 'users.create'],
      },
    ],
  },
  {
    name: 'Administrative',
    resources: [
      {
        name: 'Internal Users',
        icon: Users,
        link: 'internal-users',
        roles: ['superadmin', 'internal_admin'],
        permissions: ['internal_users.list'],
      },
      {
        name: 'Providers',
        icon: Building2,
        link: 'providers',
        roles: ['superadmin', 'internal_admin'],
        permissions: ['tenants.list'],
      },
      {
        name: 'Audit Logs',
        icon: FileText,
        link: 'audit-logs',
        roles: ['superadmin'],
        permissions: ['audit_logs.list'],
      },
      {
        name: 'Support Tickets',
        icon: FileQuestion,
        link: 'support-tickets',
        roles: ['superadmin', 'internal_admin'],
        permissions: ['support_tickets.list'],
        internal_user_only: true,
      },
      {
        name: 'Testing',
        icon: Bug,
        link: 'testing',
        roles: ['superadmin'],
        section: 'Administrative',
        internal_user_only: true,
        dev_only: true,
      },
    ],
  },
  {
    name: 'Support',
    resources: [
      {
        name: 'Help Center',
        icon: HelpCircle,
        link: 'https://vironix.notion.site/Help-Center-a26695ee626c4a0895b34f67b14caea4',
        permissions: null,
        section: '',
        type: 'external',
      },
    ],
  },
];
