import { Grid } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { MetricsEmpty } from './MetricsEmpty';
import { PatientVitals } from './PatientVitals';
import { AddObservationButton } from '@/modules/shared-device-observation/components/AddObservationButton';
import { Card } from '@/modules/ui/components/card';
import { PatientScores } from '../patient-scores/PatientScores';
import { useIsDemo } from '@/modules/demo/components/DemoProvider';
import DemoMeasurement from '@/modules/demo/components/DemoMeasurement';

export function MetricsSection() {
  const patientRecord = useRecordContext();
  const { isDemo } = useIsDemo();

  if (!patientRecord) return null;

  const observations = patientRecord.observations;
  const measureReports = patientRecord.measure_reports;

  if (
    !observations ||
    !measureReports ||
    !observations.length ||
    !measureReports.length
  )
    return (
      <div className="relative pt-10  mt-5">
        <div className="absolute right-4 top-4">
          <div className="flex flex-row gap-2">
            <AddObservationButton />
            {isDemo ? <DemoMeasurement /> : null}
          </div>
        </div>
        <Card className="p-4 mt-5">
          <MetricsEmpty />
        </Card>
      </div>
    );

  return (
    <div className="relative mt-5">
      <div className="absolute right-4 top-4">
        <div className="flex flex-row gap-2">
          <AddObservationButton />
          {isDemo ? <DemoMeasurement /> : null}
        </div>
      </div>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <PatientVitals />
        </Grid>
        <Grid item>
          <PatientScores />
        </Grid>
      </Grid>
    </div>
  );
}
