import React, { createContext, useContext, useState, useEffect } from 'react';

interface DemoContextType {
  isDemo: boolean;
  isLoading: boolean;
}

const DemoContext = createContext<DemoContextType>({
  isDemo: false,
  isLoading: true,
});
const apiUrl = window.config.apiUrl;

export const useIsDemo = () => useContext(DemoContext);

export const DemoProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isDemo, setIsDemo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkDemoStatus = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setIsDemo(data.is_demo);

        if (data.is_demo) console.log('Demo mode is enabled');
      } catch (error) {
        console.error('Failed to fetch demo status:', error);
        setIsDemo(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkDemoStatus();
  }, []);

  return (
    <DemoContext.Provider value={{ isDemo, isLoading }}>
      {children}
    </DemoContext.Provider>
  );
};
