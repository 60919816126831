import { MuiTelInput } from 'mui-tel-input';
import React from 'react';

import clsx from 'clsx';
import { FieldTitle, InputHelperText } from 'react-admin';
import { useController } from 'react-hook-form';
const defaultInputLabelProps = { shrink: true };

export const PhoneNumberInput = (props) => {
  const {
    source,
    margin = 'dense',
    resource,
    helperText,
    required,
    label,
    className,
  } = props;
  const {
    field,
    fieldState: { error, isTouched },
    formState: { isSubmitted },
  } = useController({
    name: source,
    defaultValue: '',
    rules: { required: required },
  });

  return (
    <MuiTelInput
      {...field}
      defaultCountry="US"
      className={clsx('ra-input', `ra-input-${source}`, className)}
      size="small"
      margin={margin}
      variant="filled"
      label={
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={required}
        />
      }
      helperText={
        <InputHelperText
          touched={isTouched || isSubmitted}
          error={error?.message}
          helperText={helperText}
        />
      }
      InputLabelProps={defaultInputLabelProps}
    />
  );
};
