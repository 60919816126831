import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/modules/ui/components/table';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import React, { useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Button } from '../../ui/components/button';
import { cn } from '@/modules/ui/utils/cn';
import { ArrowUpDown, ArrowUp, ArrowDown } from 'lucide-react';

export const SaveRowButton = ({ onSaveRow }) => {
  const { formState, handleSubmit, reset } = useFormContext();

  if (!formState.isDirty) {
    return null;
  }

  const click = () => {
    handleSubmit((data) => {
      onSaveRow(data, formState.defaultValues);
    })();
    reset();
  };

  return (
    <span className="flex gap-2">
      <Button
        type="button"
        onClick={() => reset()}
        variant="outline"
        className="h-6"
      >
        Reset
      </Button>
      <Button type="button" onClick={click} className="h-6">
        Save
      </Button>
    </span>
  );
};

const EditableRow = ({ row, onSaveRow, schema = null, data }) => {
  const methods = useForm({
    defaultValues: data,
    resolver: zodResolver(schema),
  });

  return (
    <TableRow>
      <FormProvider {...methods}>
        {row.getVisibleCells().map((cell) => (
          <TableCell className="py-1" key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        ))}
        <TableCell>
          <SaveRowButton onSaveRow={onSaveRow} />
        </TableCell>
      </FormProvider>
    </TableRow>
  );
};

export const SimpleTable = ({
  data,
  columns,
  caption = null,
  isEditable = false,
  onSaveRow = null,
  schema = null,
  tableProps = {},
  showHeader = true,
  rowClick = null,
  onSort = null,
  sorting: initialSorting = [],
}) => {
  const [sorting, setSorting] = useState<SortingState>(initialSorting);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: (updater) => {
      const newSorting =
        typeof updater === 'function' ? updater(sorting) : updater;
      setSorting(newSorting);
      if (onSort) {
        onSort(newSorting);
      }
    },
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    ...tableProps,
  });

  if (!data || !data.length) {
    return null;
  }

  return (
    <Table>
      {caption && <TableCaption>{caption}</TableCaption>}
      <TableHeader className={showHeader ? '' : 'hidden'}>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const isSortable = header.column.getCanSort() && onSort !== null;
              return (
                <TableHead
                  key={header.id}
                  className={cn(
                    (header.column.columnDef.meta as any)?.headerClassName,
                    isSortable && 'cursor-pointer select-none',
                  )}
                  onClick={
                    isSortable
                      ? header.column.getToggleSortingHandler()
                      : undefined
                  }
                >
                  {header.isPlaceholder ? null : (
                    <div className="flex items-center">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {isSortable && (
                        <span className="ml-2">
                          {header.column.getIsSorted() === 'asc' ? (
                            <ArrowUp className="h-4 w-4" />
                          ) : header.column.getIsSorted() === 'desc' ? (
                            <ArrowDown className="h-4 w-4" />
                          ) : (
                            <ArrowUpDown className="h-4 w-4" />
                          )}
                        </span>
                      )}
                    </div>
                  )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => {
            if (isEditable) {
              return (
                <EditableRow
                  key={row.id}
                  row={row}
                  onSaveRow={onSaveRow}
                  schema={schema}
                  data={data[row.index]}
                />
              );
            }
            return (
              <TableRow
                key={row.id}
                onClick={() => rowClick && rowClick(row)}
                className={cn(rowClick && 'cursor-pointer')}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell className="py-1" key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
