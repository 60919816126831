import { Link, useMatch } from 'react-router-dom';
import { buttonVariants } from '@/modules/ui/components/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/modules/ui/components/tooltip';
import React from 'react';
import { cn } from '@/modules/ui/utils/cn';
import { useSidebarState } from '../hooks/useSidebarState';
import { useBasename } from 'ra-core';

export function SidebarItem(props) {
  const { item } = props;

  const [sidebarOpen] = useSidebarState();
  const basename = useBasename();

  const to = item.to || '';
  const match = useMatch({ path: to, end: to === `${basename}/` });

  const externalLink = item.to.startsWith('http');

  if (!sidebarOpen) {
    return (
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link
              to={to}
              className={cn(
                buttonVariants({
                  variant: match ? 'default' : 'ghost',
                  size: 'icon',
                }),
                'h-9 w-9',
                'ml-2',
                match &&
                  'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white',
                {
                  'text-gray-700 dark:text-muted-foreground': !match,
                },
              )}
              target={externalLink ? '_blank' : ''}
            >
              <item.icon className="h-5 w-5" />
              <span className="sr-only">{item.title}</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right" className="flex items-center gap-4">
            {item.title}
            {item.label && (
              <span className="ml-auto text-muted-foreground">
                {item.label}
              </span>
            )}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }
  return (
    <Link
      to={to}
      className={cn(
        buttonVariants({ variant: match ? 'default' : 'ghost', size: 'sm' }),
        match &&
          'dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white',
        'justify-start',
        {
          'text-gray-700 dark:text-muted-foreground': !match,
        },
      )}
      target={externalLink ? '_blank' : ''}
    >
      <item.icon className="mr-2 h-5 w-5" />
      {item.title}
      {item.label && (
        <span
          className={cn('ml-auto', match && 'text-background dark:text-white')}
        >
          {item.label}
        </span>
      )}
    </Link>
  );
}
