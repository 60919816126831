import React from 'react';
import {
  FormDataConsumer,
  SelectInput,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin';
import { unenrollReasons } from '../constants/unenrollReasons';
import CreateDialog from '@/modules/ra-ui/components/create-dialog';

// "died", "left service", "never used/received device", "non-responsive"

export const UnenrollPatientDialog = ({ open, setOpen }) => {
  const record = useRecordContext();

  const transform = (data) => {
    return { ...data, start_date: new Date().toISOString() };
  };

  if (!record) return null;

  if (record.patient_status === 'disabled') return null;

  return (
    <CreateDialog
      title="Unenroll patient"
      resource={`patients/${record?.id}/unenroll`}
      transform={transform}
      notifyMessage="Patient has been successfully unenrolled."
      open={open}
      setOpen={setOpen}
      refreshOnSuccess
    >
      <div className="flex flex-col gap1">
        <SelectInput
          source="reason"
          choices={unenrollReasons}
          optionText="value"
          helperText={false}
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return formData.reason === 'other' ? (
              <TextInput
                source="reason_other"
                label="Other Reason"
                validate={required()}
              />
            ) : null;
          }}
        </FormDataConsumer>
      </div>
    </CreateDialog>
  );
};
