const roleChoices = [
  { id: 'admin', name: 'Admin' },
  { id: 'clinical_staff', name: 'Clinical Staff' },
  { id: 'staff_member', name: 'Staff Member' },
];

const internalRoles = [
  {
    id: 'superadmin',
    name: 'Super Admin',
    description: 'Highest level of access. Can do anything.',
    disabled: true,
  },
  {
    id: 'internal_admin',
    name: 'Admin',
    description:
      'Can manage internal users, add new providers and access all providers. Can manage provider account & provider users. Lacks access to certain features and toggles.',
  },
  {
    id: 'clinical_specialist',
    name: 'Specialist',
    description:
      'Internal user role that requires specific access to tenants to perform clinical tasks.',
  },
];

export { roleChoices, internalRoles };
