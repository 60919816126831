import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import React from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  useRecordContext,
} from 'react-admin';

const filterTenant = (searchText) => ({
  q: { field: 'name', value: searchText },
});

// todo - better success message
export const TenantUserRoleAdd = ({ variant = 'contained' }) => {
  const record = useRecordContext();

  const transform = (data) => {
    return { ...data, start_date: new Date().toISOString() };
  };

  return (
    <CreateInDialogButton
      title={`Add new access for ${record.full_name}`}
      resource={`users/${record?.id}/tenant-user-roles/`}
      transform={transform}
      variant={variant}
      label="Access"
      notifyMessage="Role added for user"
    >
      <div className="flex flex-col gap-1">
        <ReferenceInput source="tenant_id" reference="providers">
          <AutocompleteInput
            label="Provider"
            optionText="name"
            validate={required()}
            helperText={false}
            filterToQuery={filterTenant}
          />
        </ReferenceInput>
      </div>
    </CreateInDialogButton>
  );
};
