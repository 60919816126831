import { Card } from '@/modules/ui/components/card';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { useGetList, useRecordContext } from 'react-admin';
import { AISummariesEmpty } from './AISummariesEmpty';
import { AddAISummaryButton } from './AddAISummaryButton';

export function AISummariesSection() {
  const patientRecord = useRecordContext();

  const { data } = useGetList(
    'foundation-model-responses/latest',
    {
      meta: {
        patient_id: patientRecord?.user.id,
      },
      pagination: {
        page: 1,
        perPage: 1,
      },
      sort: { field: 'created_at', order: 'DESC' },
    },
    {
      enabled: !!patientRecord,
    },
  );

  if (!patientRecord) return null;

  if (!data || data.length === 0) {
    return (
      <div className="relative pt-10 mt-5">
        <div className="absolute right-4 top-4">
          <AddAISummaryButton />
        </div>
        <Card className="p-4 mt-5">
          <AISummariesEmpty />
        </Card>
      </div>
    );
  }
  return (
    <div className="relative mt-5">
      <div className="absolute right-4 top-4">
        {get(data, '[0].id') ? <AddAISummaryButton /> : null}
      </div>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col space-y-2">
          <h3 className="text-xl font-semibold">AI Summary</h3>
          <p className="text-sm text-gray-500">
            Patient summaries generated using large language models.
          </p>
        </div>
        <div className="flex flex-col space-y-4 justify-center">
          <div className="p-4 bg-white">
            <p className="mb-1 p-3">{get(data, '[0].content')}</p>
          </div>
          {get(data, '[0].id') &&
          get(data, '[0].additional_info.patient_summary_metadata') ? (
            <footer className="border-t border-gray-150 mt-4 pt-2 text-sm text-gray-500 flex justify-between items-center">
              Data from{' '}
              {DateTime.fromISO(
                get(
                  data,
                  '[0].additional_info.patient_summary_metadata.start_date',
                ),
              ).toLocaleString(DateTime.DATE_MED)}{' '}
              to{' '}
              {DateTime.fromISO(
                get(
                  data,
                  '[0].additional_info.patient_summary_metadata.end_date',
                ),
              ).toLocaleString(DateTime.DATE_MED)}{' '}
              was used to generate the summary.
              <br />
              Summary generated on:{' '}
              {DateTime.fromISO(get(data, '[0].created_at')).toLocaleString(
                DateTime.DATE_MED,
              )}
              <br />
              <div className="bg-amber-50 rounded-lg p-1">
                <span className="text-xs font-semibold text-amber-800">
                  AI Generated
                </span>
              </div>
            </footer>
          ) : null}
        </div>
      </div>
    </div>
  );
}
