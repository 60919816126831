import { Badge } from '@/modules/ui/components/badge';
import { Button } from '@/modules/ui/components/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/modules/ui/components/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/modules/ui/components/popover';
import { Separator } from '@/modules/ui/components/separator';
import { cn } from '@/modules/ui/utils/cn';
import { get, debounce } from 'lodash';
import { CheckIcon, PlusCircle, Search } from 'lucide-react';
import React, { useRef, useState } from 'react';
import { useListContext, useGetList } from 'react-admin';

export interface DataTableReferenceFilterProps {
  column: string;
  title: string;
  resource: string;
  perPage?: number;
  optionText?: string;
  optionValue?: string;
  customFilter?: (query: string) => Record<string, any>;
}

export default function DataTableReferenceFilter({
  column,
  title,
  resource,
  perPage = 10,
  optionText = 'name',
  optionValue = 'id',
  customFilter,
}: DataTableReferenceFilterProps) {
  const { filterValues, setFilters } = useListContext();
  const [searchQuery, setSearchQuery] = useState('');
  const [page] = useState(1);
  const inputRef = useRef<HTMLInputElement>(null);

  const selectedValue = get(filterValues, column);

  const defaultFilter = (query: string) => ({
    ...(query &&
      query.length > 1 && {
        q: { field: optionText, value: query },
      }),
  });

  const { data: options = [] } = useGetList(resource, {
    pagination: { page, perPage },
    filter: customFilter
      ? customFilter(searchQuery)
      : defaultFilter(searchQuery),
    sort: { field: optionText, order: 'DESC' },
  });

  const delayedSearch = useRef(
    debounce((q: string) => {
      setSearchQuery(q);
    }, 300),
  ).current;

  const handleSearch = (query: string) => {
    delayedSearch(query);
  };

  const clearFilters = () => {
    setFilters({ ...filterValues, [column]: undefined }, null);
    setSearchQuery('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 border-dashed">
          <PlusCircle className="mr-2 h-4 w-4" />
          {title}
          {selectedValue && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <div className="hidden space-x-1 lg:flex">
                {options
                  .filter((option) => selectedValue === option[optionValue])
                  .map((option) => (
                    <Badge
                      variant="secondary"
                      key={option[optionValue]}
                      className="rounded-sm px-1 font-normal"
                    >
                      {option[optionText]}
                    </Badge>
                  ))}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <div className="flex items-center border-b px-3">
            <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
            <input
              ref={inputRef}
              className={cn(
                'flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
              )}
              placeholder={title}
              onChange={(e) => handleSearch(e.target.value)}
              defaultValue={searchQuery}
            />
          </div>
          <CommandList>
            {options.length === 0 ? (
              <CommandEmpty>No results found.</CommandEmpty>
            ) : (
              <CommandGroup>
                {options.map((option) => {
                  const isSelected = selectedValue === option[optionValue];
                  return (
                    <CommandItem
                      key={option[optionValue]}
                      onSelect={() => {
                        setFilters(
                          {
                            ...filterValues,
                            [column]: isSelected
                              ? undefined
                              : option[optionValue],
                          },
                          null,
                        );
                      }}
                    >
                      <div
                        className={cn(
                          'mr-2 flex h-4 w-4 items-center justify-center rounded-lg border border-primary',
                          isSelected
                            ? 'bg-primary text-primary-foreground'
                            : 'opacity-50 [&_svg]:invisible',
                        )}
                      >
                        <CheckIcon className={cn('h-4 w-4')} />
                      </div>
                      <span>{option[optionText]}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            )}
            {selectedValue && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={clearFilters}
                    className="justify-center text-center"
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
