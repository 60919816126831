export const unenrollReasons = [
  {
    id: 'no-longer-eligible',
    value: 'No longer eligible',
  },
  {
    id: 'patient-request',
    value: 'Patient request',
  },
  {
    id: 'patient-deceased',
    value: 'Patient deceased',
  },
  {
    id: 'hospice',
    value: 'Entered hospice or long-term-care',
  },
  {
    id: 'non-responsive',
    value: 'Non-responsive',
  },
  {
    id: 'never-started',
    value: 'Never started',
  },
  {
    id: 'breach-of-contract',
    value: 'Breach of contract',
  },
  {
    id: 'customer-contract-terminated',
    value: 'Physician contract discontinued',
  },
  {
    id: 'physician-request',
    value: 'Physician request',
  },
  {
    id: 'other',
    value: 'Other',
  },
];
