import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import { MiniDeleteButton } from '@/modules/ra-ui/components/mini-delete-button';
import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  WithRecord,
  required,
  useGetIdentity,
  useGetOne,
  useRecordContext,
} from 'react-admin';
import { TaskActivities } from './TaskActivities';

const filterUserName = (searchText) => ({
  q: { field: 'user.full_name', value: searchText },
});

const AssignedToField = ({ patient_tenant_id = null }) => {
  const record = useRecordContext();
  const { data: patient } = useGetOne(
    'patients',
    { id: record?.patient_id },
    { enabled: !!record && !patient_tenant_id },
  );

  let filters: any = {
    'role[ne]': `patient`,
    'tenant_id[in]': [patient_tenant_id || patient?.user.tenant_id, null],
  };

  return (
    <ReferenceInput source="assigned_to_id" reference="users" filter={filters}>
      <AutocompleteInput
        label="Assigned to"
        optionText={(choice) => {
          if (choice.is_internal) {
            return `${choice.first_name} ${choice.last_name} (Vironix) ${
              choice.credentials ? `(${choice.credentials})` : ``
            }`;
          }
          return `${choice.full_name} ${
            choice.credentials ? `(${choice.credentials})` : ``
          }`;
        }}
        parse={(v) => (v === '' ? null : v)}
        helperText={false}
        filterToQuery={filterUserName}
      />
    </ReferenceInput>
  );
};

export function TaskEditButton({ patient_tenant_id = null }) {
  const { identity } = useGetIdentity();

  const transform = (data, { previousData }) => {
    if (data.complete && !!!previousData.complete) {
      return {
        ...data,
        complete: true,
        completed_at: new Date().toISOString(),
        completed_by_id: identity?.id,
      };
    }

    return data;
  };

  return (
    <EditInDialogButton
      title="Edit task"
      resource="tasks"
      transform={transform}
      fullWidth
      notifyMessage="Task updated"
      refreshOnSuccess
      closeOnClickOutside
    >
      <div className="flex flex-col gap-2">
        <TextInput source="title" validate={required()} helperText={false} />
        <TextInput
          source="description"
          helperText={false}
          multiline
          minRows={2}
        />
        <DateInput
          source="due_on"
          parse={(v) => new Date(v).toISOString().split('T', 1)[0]}
          helperText={false}
        />
        <SelectInput
          source="priority"
          choices={[
            { id: 1, name: 'High' },
            { id: 2, name: 'Medium' },
            { id: 3, name: 'Low' },
          ]}
          helperText={false}
        />
        <AssignedToField patient_tenant_id={patient_tenant_id} />
        <WithRecord
          render={(record) =>
            record.added_by_name && (
              <TextInput
                source="added_by_name"
                label="Added by"
                helperText={false}
                disabled
              />
            )
          }
        />
        <WithRecord
          render={(record) =>
            record.completed_by_name && (
              <TextInput
                source="completed_by_name"
                label="Completed by"
                helperText={false}
                disabled
              />
            )
          }
        />
        <div className="flex flex-row gap-1 items-center justify-between">
          <BooleanInput source="complete" helperText={false} />
          <MiniDeleteButton resource="tasks" />
        </div>
        <TaskActivities showSeperator items={5} />
      </div>
    </EditInDialogButton>
  );
}
