import { Button } from '@/modules/ui/components/button';
import { Bug } from 'lucide-react';
import React from 'react';
import {
  BooleanInput,
  SelectInput,
  TextInput,
  useGetIdentity,
  useRecordContext,
} from 'react-admin';

import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';

const DeviceReportIssueButton = () => {
  const record = useRecordContext();
  const { isLoading: identityLoading, identity } = useGetIdentity();

  if (identityLoading || !identity || !record) {
    return null;
  }

  return (
    <CreateInDialogButton
      title="Report device issue"
      resource="device-activities"
      variant="default"
      label="Report Issue"
      notifyMessage="Issue Reported."
      transform={(data) => {
        return {
          ...data,
          activity_type: 'issue_report',
          device_id: record.id,
          actor_id: identity.id,
        };
      }}
      createButton={
        <Button variant="outline" size="sm">
          <Bug className="w-4 h-4 mr-2" />
          Report Issue
        </Button>
      }
    >
      <div className="flex flex-col">
        <SelectInput
          source="value.issue_type"
          label="Issue Type"
          choices={[
            { id: 'battery', name: 'Battery' },
            { id: 'connection', name: 'Connection' },
            { id: 'hardware', name: 'Hardware' },
            { id: 'user error', name: 'User Error' },
            { id: 'other', name: 'Other' },
          ]}
          helperText={false}
        />
        <TextInput
          source="value.description"
          label="Description"
          helperText={false}
          multiline
          minRows={3}
        />
        <BooleanInput
          source="value.is_resolved"
          label="Resolved"
          helperText={false}
        />
        <BooleanInput
          source="value.reported_by_patient"
          label="Reported by Patient"
          helperText={false}
        />
      </div>
    </CreateInDialogButton>
  );
};

export default DeviceReportIssueButton;
