import { fetchUtils } from 'react-admin';
import inMemoryJWT from './inMemoryJWT';

export const httpClient = async (url: any, options: any) => {
  if (!options) {
    options = {};
  }
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const token = await inMemoryJWT.getToken('httpClient');

  options.headers.set('Authorization', `Bearer ${token}`);

  return fetchUtils.fetchJson(url, options);
};
