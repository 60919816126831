import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Alert, Snackbar, TextField } from '@mui/material';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useRecordContext, useStore } from 'react-admin';
import { CustomPallete } from '../../../../utils/color';

export const InviteCodeInput = () => {
  const record = useRecordContext();
  const [isOpen, setIsOpen] = useState(false);
  const [otp, setOtp] = useStore('otp', '');

  const copyToClipBoard = () => {
    setIsOpen(true);
    navigator.clipboard.writeText(otp);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    return () => {
      setOtp('');
    };
  }, [setOtp]);

  if (!record || !otp) return null;

  return (
    <>
      <TextField
        label="Invitation code"
        value={otp}
        InputProps={{
          readOnly: true,
          endAdornment: get(record, 'user.first_otp') ? (
            <ContentCopyIcon style={{ color: CustomPallete.neutral5 }} />
          ) : (
            <></>
          ),
        }}
        onClick={get(record, 'user.first_otp') ? copyToClipBoard : null}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isOpen}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert severity="success">Code copied to clipboard</Alert>
      </Snackbar>
    </>
  );
};
