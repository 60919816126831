import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import { PhoneNumberInput } from '@/modules/ra-ui/components/PhoneNumberInput';
import {
  CardDescription,
  CardFooter,
  CardTitle,
} from '@/modules/ui/components/card';

import { Button } from '@/modules/ui/components/button';
import { Card, CardContent, CardHeader } from '@/modules/ui/components/card';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/modules/ui/components/tabs';
import { Stack, Typography } from '@mui/material';

import { AssignToProviderButton } from '@/modules/ra-ui/components/AssignToProviderButton';
import { ColoredChipField } from '@/modules/ra-ui/components/ColoredChipField';
import AppTitle from '@/modules/layout/components/app-title';
import { SaveButton } from '@/modules/ra-ui/components/save-button';
import { cn } from '@/modules/ui/utils/cn';
import { monitoringConditionColor } from '@/utils/color';
import { conditionNames } from '@/modules/patients/constants/conditionNames';
import { get, set, unset } from 'lodash';
import React from 'react';
import {
  BooleanInput,
  Datagrid,
  DeleteButton,
  EditBase,
  Form,
  Pagination,
  RadioButtonGroupInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  maxLength,
  minLength,
  required,
  useGetList,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';
import { PermissionsTab } from '../../modules/users/components/Permissions';
import { roleChoices } from '../../modules/users/constants/definitions';
import PasswordResetButton from '@/modules/ra-ui/components/PasswordResetButton';
import { AuthLogPanel } from '@/modules/users/components/AuthLogPanel';
import TwoFAResetButton from '@/modules/users/components/TwoFAResetButton';

export function UserShowEdit() {
  const editTransform = (data, { previousData }) => {
    // if role is unchanged, remove it from the data
    if (previousData && previousData.role === data.role) {
      unset(data, 'role');
    }

    if (get(data, 'phone_number') === '+1') {
      set(data, 'phone_number', null);
    }
    return data;
  };

  return (
    <div className="m-4 mb-2">
      <AppTitle title="Edit user" />
      <EditBase redirect={null} transform={editTransform} resource="users">
        <Form>
          <UserShowEditBody />
        </Form>
      </EditBase>
    </div>
  );
}

const UserShowEditBody = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();

  if (!record) {
    return null;
  }

  return (
    <div>
      <Tabs defaultValue="account" className=" pt-2">
        <TabsList
          className={cn('grid w-full grid-cols-3', {
            'grid-cols-4': get(record, 'is_internal'),
          })}
        >
          <TabsTrigger value="account">Account</TabsTrigger>
          {get(record, 'is_internal') ? (
            <TabsTrigger value="permissions">Permissions</TabsTrigger>
          ) : null}
          <TabsTrigger value="patients">Patients</TabsTrigger>
          <TabsTrigger value="auth-log">Security</TabsTrigger>
        </TabsList>
        <TabsContent value="account">
          <Card>
            <CardHeader>
              <CardTitle>Account</CardTitle>
              <CardDescription>
                Make changes to the user account here. Click save when you're
                done.
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-2">
              <Stack>
                <Stack gap={1} direction="row">
                  <TextInput source="first_name" validate={[required()]} />
                  <TextInput source="middle_name" />
                  <TextInput source="last_name" validate={[required()]} />
                </Stack>
                <Stack gap={1} direction="row">
                  <TextInput
                    source="email"
                    type="email"
                    validate={[required()]}
                  />
                  <PhoneNumberInput
                    label="Phone Number"
                    source="phone_number"
                  />
                </Stack>
                {!!!record.is_internal && get(permissions, 'is_internal') ? (
                  <Stack gap={1} direction="row">
                    <ReferenceInput source="tenant_id" reference="providers">
                      <SelectInput
                        label="Provider"
                        optionText="name"
                        disabled
                        sx={{
                          minWidth: 400,
                        }}
                        source="name"
                        helperText="Use the `Set Provider` button to change the provider."
                      />
                    </ReferenceInput>
                  </Stack>
                ) : null}
                <Stack gap={1} direction="row">
                  <TextInput
                    source="npi_number"
                    label="NPI Number"
                    helperText="10-digit NPI Number"
                    validate={[
                      minLength(10, 'NPI number must be 10 digits'),
                      maxLength(10, 'NPI number must be 10 digits'),
                    ]}
                  />
                  <TextInput source="credentials" helperText="MD, RN, PhD" />
                  <BooleanInput source="is_provider" label="Is clinician" />
                </Stack>
                {!record.is_internal ? (
                  <RadioButtonGroupInput source="role" choices={roleChoices} />
                ) : null}

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="caption">
                    Welcome Email Last Sent
                  </Typography>
                  <LuxonDateField
                    source="welcome_email_last_sent"
                    showTime
                    emptyText="Never"
                  />
                </Stack>
              </Stack>
            </CardContent>
            <CardFooter className="flex flex-row justify-between">
              <Stack direction="row" alignItems="center" spacing={2}>
                <EnableUserButton source="is_active" />
                <PasswordResetButton />
                <TwoFAResetButton />
                {record.is_internal ? null : <AssignToProviderButton />}
              </Stack>
              <SaveButton variant="default" type="submit" />
            </CardFooter>
          </Card>
        </TabsContent>
        {get(record, 'is_internal') ? (
          <TabsContent value="permissions">
            <PermissionsTab />
          </TabsContent>
        ) : null}
        <TabsContent value="patients">
          <PatientsUnderCareTab />
        </TabsContent>
        <TabsContent value="auth-log">
          <AuthLogPanel userId={record.id} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

const EnableUserButton = (props) => {
  const { source } = props;
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const [update, { isLoading }] = useUpdate(
    'users',
    {
      id: record?.id,
      data: { [source]: record?.is_active ? false : true },
    },
    {
      onSuccess: (data) => {
        notify(record?.is_active ? 'User disabled' : 'User enabled');
        refresh();
      },
    },
  );

  if (!record) {
    return null;
  }

  const handleClick = () => update();

  return record.is_active ? (
    <Button
      disabled={isLoading}
      variant="outline"
      color="error"
      onClick={handleClick}
      type="button"
    >
      Disable User
    </Button>
  ) : (
    <Button
      disabled={isLoading}
      variant="outline"
      onClick={handleClick}
      type="button"
    >
      Enable User
    </Button>
  );
};

const PatientsUnderCareTab = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();
  const sort = { field: null, order: null };
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  const { data, total, isLoading } = useGetList(
    `users/${record.id}/care-teams`,
    {
      pagination: { page, perPage },
      filter: {},
      sort,
    },
  );

  if (!record) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Patients</CardTitle>
        <CardDescription>
          View patients under care for this particular user. You can add
          clinicians to a patient's care team from the patient's profile.
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-2">
        <>
          <Datagrid
            size="small"
            data={data}
            total={total}
            isLoading={isLoading}
            sort={sort}
            bulkActionButtons={false}
            setSort={() => {}}
            empty={
              <p className="text-sm text-muted-foreground">
                User has no patients under care.
              </p>
            }
          >
            <TextField source="patient_name" label="Name" />
            <ColoredChipField
              source="primary_condition"
              colorScheme={monitoringConditionColor}
              mapping={conditionNames}
            />
            {get(permissions, 'is_internal') ? (
              <ReferenceField
                label="Provider"
                source="patient_tenant_id"
                reference="providers"
                link="show"
                emptyText="None"
              >
                <TextField source="name" />
              </ReferenceField>
            ) : null}
            <DeleteCareMemberButton />
          </Datagrid>
          <Pagination
            total={total}
            perPage={perPage}
            page={page}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        </>
      </CardContent>
    </Card>
  );
};

const DeleteCareMemberButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();

  return (
    <DeleteButton
      resource={`patients/${record?.patient_id}/care-teams`}
      redirect={false}
      mutationMode="pessimistic"
      label=""
      confirmTitle="Remove patient from care of this user?"
      confirmContent="This will remove the patient from the care of this user."
      mutationOptions={{
        onSuccess: () => {
          refresh();
        },
      }}
      sx={{
        minWidth: 'unset',
        '& .MuiButton-startIcon': {
          mr: 0,
        },
      }}
    />
  );
};
