import React from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceArrayInput,
  ReferenceInput,
  useGetList,
  useStore,
} from 'react-admin';

const formatICD10Code = (value) => {
  // add dot after 3rd character ie. I501 -> I50.1, only if there is more than 3 characters

  let id = value.id.slice(0, 3);
  if (value.id.slice(3)) {
    id = value.id.slice(0, 3) + '.' + value.id.slice(3);
  }

  return `${id} - ${value.description}`;
};

const maxCount =
  (message = 'Max of 4 choices') =>
  (value) =>
    value && value.length > 4 ? message : undefined;

export const ICD10ArrayInput = ({
  validate = [maxCount()],
  source = 'icd10_ids',
}) => {
  return (
    <ReferenceArrayInput
      source={source}
      reference="icd10"
      enableGetChoices={({ q }) => q && q.length >= 3}
    >
      <AutocompleteArrayInput
        label="Diagnosis Codes"
        clearOnBlur={false}
        optionText={formatICD10Code}
        helperText={false}
        validate={validate}
        sx={{ maxWidth: '400px' }}
      />
    </ReferenceArrayInput>
  );
};

export const ICD10ArrayPatientInput = ({
  patientId,
  validate = [maxCount()],
  source = 'icd10_ids',
}) => {
  const { data } = useGetList(
    `patients/${patientId}/conditions`,
    {
      pagination: { page: 1, perPage: 50 },
      filter: {
        'source[eq]': 'vironix',
        'category[eq]': 'diagnosis',
      },
      sort: { field: 'created_at', order: 'DESC' },
    },
    {
      enabled: !!patientId,
    },
  );

  const formatText = (value) => {
    // add dot after 3rd character ie. I501 -> I50.1, only if there is more than 3 characters

    let id = value.id.slice(0, 3);
    if (value.id.slice(3)) {
      id = value.id.slice(0, 3) + '.' + value.id.slice(3);
    }

    return `${id} - ${value.name}`;
  };

  if (!patientId || !data) {
    return null;
  }

  const choices = data.map((condition: any) => ({
    id: condition.additional_info.icd10_id,
    name: condition.name,
  }));

  return (
    <AutocompleteArrayInput
      label="Diagnosis Codes"
      clearOnBlur={false}
      optionText={formatText}
      helperText="Only ICD10 codes from the patient's diagnosis are available."
      validate={validate}
      sx={{ maxWidth: '400px' }}
      source={source}
      choices={choices}
    />
  );
};

interface ICD10InputProps {
  validate?: any[];
  source?: string;
}

export const ICD10Input = ({
  validate = [maxCount()],
  source = 'icd10_id',
}: ICD10InputProps) => {
  const [recentICD10, setRecentICD10] = useStore('recentICD10', []);
  const onSelectCode = (value) => {
    if (!value) return;

    // Add the selected code to the recent list
    let updatedRecentICD10 = [value, ...recentICD10];
    // filter any duplicates
    updatedRecentICD10 = updatedRecentICD10.filter(
      (v, i, a) => a.findIndex((t) => t === v) === i,
    );
    // Cap the list to 12 items
    updatedRecentICD10 = updatedRecentICD10.slice(0, 12);

    // Update the store with the new list
    setRecentICD10(updatedRecentICD10);
  };

  console.log('ICD10Input - Recent Codes:', recentICD10);
  return (
    <ReferenceInput
      source={source}
      reference="icd10"
      enableGetChoices={({ q }) => q && q.length >= 3}
      queryOptions={{ meta: { recent_icd10_codes: recentICD10 } }}
    >
      <AutocompleteInput
        label="Diagnosis Code"
        clearOnBlur={false}
        optionText={formatICD10Code}
        helperText={false}
        validate={validate}
        sx={{ maxWidth: '400px' }}
        onChange={onSelectCode}
      />
    </ReferenceInput>
  );
};
