import React from 'react';
import {
  Pagination,
  RecordContextProvider,
  useDataProvider,
  useGetList,
  useRecordContext,
} from 'react-admin';

import { useQuery } from 'react-query';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/modules/ui/components/table';

import { formatPastDateTime } from '@/utils/formatPastDateTime';

import inflection from 'inflection';
import { get, isEmpty, map, has, reduce, remove } from 'lodash';
import { triageColors } from '../../../../utils/color';
import { getAlgorithmResponse } from '../utils/getAlgorithmResponse';
import { getRunScore } from '../utils/getRunScore';
import { AssessmentsEmpty } from './AssessmentsEmpty';

import { Badge } from '@/modules/ui/components/badge';
import { Button } from '@/modules/ui/components/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/modules/ui/components/tooltip';
import { cn } from '@/modules/ui/utils/cn';
import { ClipboardList, Cpu, RefreshCcw } from 'lucide-react';
import { assessmentsNames } from '../../../users/constants/assessmentsNames';
import { triageText } from '../constants/triageText';
import { AddAssessmentButton } from './AddAssessmentButton';
import { AssessmentReview } from './AssessmentReview';
import AssessmentTypeChart from './AssessmentTypeChart';
import { Skeleton } from '@/modules/ui/components/skeleton';

const getAssessmentScore = (run) => {
  if (get(run, 'name') === 'phq9') {
    return <p>{get(run, ['result', 0, 'response', 'phq9_score'])}</p>;
  }

  if (get(run, 'name') === 'hads') {
    const hads_a_score = get(run, [
      'result',
      0,
      'response',
      'anxiety',
      'score',
    ]);
    const hads_d_score = get(run, [
      'result',
      0,
      'response',
      'depression',
      'score',
    ]);
    return (
      <div className="flex flex-col gap-1 ">
        <p className="text-xs">HADS-A </p>
        <p className="">{hads_a_score}</p>
        <p className="text-xs">HADS-D </p>
        <p className="">{hads_d_score}</p>
      </div>
    );
  }

  if (run.name === 'cystic_fibrosis_qol') {
    return <p className="text-xs">Press view to see scores</p>;
  }

  switch (run.category) {
    case 'prediction':
      return get(getRunScore(run, new RegExp(/_primary/)), 'value');
    case 'survey':
      return get(run, ['result', 0, 'response', 'score']);
  }
};

const prepareTriageByNameForPieChart = (triageByName) => {
  const temp = {};
  map(triageByName, (o) =>
    map(o, (o, k) => {
      if (has(temp, k)) {
        temp[k] += o;
      } else {
        temp[k] = o;
      }
    }),
  );

  const output = reduce(
    temp,
    (acc, v, k) => [...acc, { name: k, value: v }],
    [],
  );

  // combine ER_override and DOC_override into ER and DOC
  output.push({
    name: 'ER',
    value: output.find((o) => o.name === 'ER_override')?.value,
  });
  output.push({
    name: 'DOC',
    value: output.find((o) => o.name === 'DOC_override')?.value,
  });

  //remove them from the output
  remove(
    output,
    (item) => item.name === 'ER_override' || item.name === 'DOC_override',
  );

  // Filter out entries with a value of 0
  remove(output, (item) => !item.value);

  return output;
};

const AssessmentItem = ({ run, patientId }) => {
  const symptoms = get(run, ['result', 0, 'observations']) || [];
  const assessmentScore = getAssessmentScore(run);

  const assessmentTriageKey = get(getAlgorithmResponse(run, 'triage'), 'key');
  const priorityColor = get(triageColors, [assessmentTriageKey], {
    primary: '',
    secondary: 'grey',
  });

  const renderIcon = () => {
    switch (run.category) {
      case 'prediction':
        return (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Cpu className="h-4 w-4" />
              </TooltipTrigger>
              <TooltipContent>
                <p>Algorithm prediction</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      case 'survey':
        return (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <ClipboardList className="h-4 w-4" />
              </TooltipTrigger>
              <TooltipContent>
                <p>Scored survey</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      default:
        return null;
    }
  };

  const resultSection = () => {
    if (run.category === 'prediction') {
      return (
        <div className="flex flex-col gap-1 items-center">
          <p>Triage</p>
          <Badge
            className="text-xs"
            style={{
              backgroundColor: get(triageColors, [
                assessmentTriageKey,
                'primary',
              ]),
              color: get(triageColors, [assessmentTriageKey, 'secondary']),
            }}
          >
            {get(triageText, assessmentTriageKey)}
          </Badge>
        </div>
      );
    }

    if (run.name === 'phq9') {
      return (
        <p className="text-xs">
          {get(run, ['result', 0, 'response', 'phq9_level']) || ''}
        </p>
      );
    }

    if (run.name === 'hads') {
      const hads_a_score = get(run, [
        'result',
        0,
        'response',
        'anxiety',
        'level',
      ]);
      const hads_d_score = get(run, [
        'result',
        0,
        'response',
        'depression',
        'level',
      ]);
      return (
        <div className="flex flex-col gap-1 text-xs">
          <p className="">HADS-A </p>
          <p className="">{hads_a_score}</p>
          <p className="">HADS-D </p>
          <p className="">{hads_d_score}</p>
        </div>
      );
    }

    return <p className="">-</p>;
  };

  return (
    <TableRow>
      <TableCell
        style={{ boxShadow: `inset 4px 0 0 ${priorityColor.secondary}` }}
      ></TableCell>
      <TableCell className="w-[200px]">
        <div className="flex flex-row gap-1 items-center justify-between">
          <div className="flex flex-col gap-1">
            <p className="text-sm">
              {get(assessmentsNames, run.name) || inflection.titleize(run.name)}
            </p>
            <p className="text-xs">{formatPastDateTime(run.created_at)}</p>
          </div>
          {renderIcon()}
        </div>
      </TableCell>
      <TableCell className="w-[140px] text-center">
        {run.category === 'prediction' ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <p className="text-sm">{assessmentScore || '-'}</p>
              </TooltipTrigger>
              <TooltipContent>
                <p>{run.category === 'prediction' ? 'Primary score' : ''}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          <p className="text-sm">{assessmentScore || '-'}</p>
        )}
      </TableCell>
      <TableCell className="w-[120px] text-center">{resultSection()}</TableCell>
      <TableCell>
        <div className="flex flex-row gap-1 max-w-[300px] min-w-[250px] items-center">
          {symptoms ? (
            symptoms
              .filter((symptom) => symptom.name === 'symptom')
              .map((symptom, index) => (
                <p key={index} className="text-sm mr-2">
                  {index !== symptoms.length - 1
                    ? `${inflection.humanize(get(symptom, 'value', ''))}, `
                    : inflection.humanize(get(symptom, 'value', ''))}
                </p>
              ))
          ) : (
            <p className="text-sm">-</p>
          )}
        </div>
      </TableCell>
      <TableCell>
        <RecordContextProvider value={{ ...run, patientId }}>
          <AssessmentReview />
        </RecordContextProvider>
      </TableCell>
    </TableRow>
  );
};

const AssessmentSummaryHeader = (props) => {
  const { summary } = props;

  if (!summary) {
    return null;
  }
  return (
    <div className="flex flex-row gap-2 items-center">
      <div className="flex flex-row gap-1 items-center">
        <p className="text-base">Last 7 days: </p>
        <p className="text-base font-semibold">
          {get(summary, 'data.total_runs_past_week')}
        </p>
      </div>
      <div className="flex flex-row gap-1 items-center">
        <p className="text-base">Last 30 days: </p>
        <p className="text-base font-semibold">
          {get(summary, 'data.total_runs_past_month')}
        </p>
      </div>
    </div>
  );
};

interface AssessmentSummaryUIProps {
  assessmentSummary: any;
  assessments: any;
  loading?: boolean;
  setIsModalOpen?: (value: boolean) => void;
  pagination?: {
    page: number;
    perPage: number;
    setPage: (value: number) => void;
    setPerPage: (value: number) => void;
    total: number;
  };
}

export const AssessmentSummaryUI = ({
  assessmentSummary,
  assessments,
  loading,
  pagination,
}: AssessmentSummaryUIProps) => {
  const record = useRecordContext();

  const renderContent = () => {
    if (loading) {
      return <Skeleton className="w-full h-[200px]" />;
    }
    if (!assessmentSummary || !assessments || !assessments.length)
      return <AssessmentsEmpty />;

    return (
      <>
        <AssessmentSummaryHeader summary={assessmentSummary} />
        <div className="flex flex-col lg:flex-row gap-2 mt-4 justify-center">
          {!isEmpty(get(assessmentSummary, 'data.triage_counts')) ? (
            <div className="flex flex-row gap-1">
              <p className="text-base font-semibold">Last 7 days</p>
              <div style={{ width: '200px', height: '300px' }}>
                <AssessmentTypeChart
                  data={prepareTriageByNameForPieChart(
                    get(assessmentSummary, 'data.triage_by_name_counts'),
                  )}
                />
              </div>
            </div>
          ) : null}
          <div className="flex flex-col gap-1 items-center  ">
            <div className="bg-white border rounded-md">
              <Table aria-label="assessment table">
                <TableHeader
                  className="bg-neutral-200 rounded-t-md border-b border-neutral-300"
                  // style={{
                  //   backgroundColor: CustomPallete.neutral2,
                  //   borderRadius: '4px 4px 0px 0px',
                  //   border: `1px solid ${CustomPallete.neutral3}`,
                  // }}
                >
                  <TableRow>
                    <TableHead></TableHead>
                    <TableHead className="text-base font-semibold">
                      Assessment
                    </TableHead>
                    <TableHead className="text-base font-semibold text-center">
                      Score
                    </TableHead>
                    <TableHead className="text-base font-semibold text-center">
                      Result
                    </TableHead>
                    <TableHead className="text-base font-semibold text-center">
                      Symptoms
                    </TableHead>
                    <TableHead></TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {assessments.map((run) => (
                    <AssessmentItem
                      key={run.id}
                      patientId={record.id}
                      run={run}
                    />
                  ))}
                </TableBody>
              </Table>
            </div>

            <Pagination
              total={pagination.total}
              perPage={pagination.perPage}
              page={pagination.page}
              setPage={pagination.setPage}
              setPerPage={pagination.setPerPage}
            />
          </div>
        </div>
      </>
    );
  };

  return renderContent();
};

export function AssessmentSummary() {
  const patientRecord = useRecordContext();

  const queryOptions = {
    refetchOnWindowFocus: false,
    refetchInterval: false as const,
  };
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(5);

  const { data, isFetching, isLoading, refetch, total } = useGetList(
    `patients/${patientRecord?.id}/runs/`,
    {
      pagination: { page, perPage },
      meta: {
        expand: ['predictions.observations', 'predictions.scores'],
        category: null,
      },
    },
    { ...queryOptions, enabled: !!patientRecord?.id },
  );

  const dataProvider = useDataProvider();
  const { data: summary, isLoading: isLoadingSummary } = useQuery(
    ['getAssessmentSummary', patientRecord?.id],
    () =>
      dataProvider.getCustom(`patients/${patientRecord?.id}/runs/summary/`, {}),
    { ...queryOptions, enabled: !!patientRecord?.id },
  );

  return (
    <div>
      <div className="flex justify-between items-center mb-2.5">
        <div>
          <h3 className="text-xl font-semibold">Assessments</h3>
          <p className="text-sm text-gray-500">
            Patient assessments and surveys
          </p>
        </div>

        <div className="flex flex-row gap-1">
          <AddAssessmentButton />
          <Button
            size="sm"
            className="h-8"
            variant="outline"
            onClick={() => refetch()}
            disabled={isFetching}
          >
            <RefreshCcw
              className={cn('h-4 w-4', {
                'animate-spin': isFetching,
              })}
            />
          </Button>
        </div>
      </div>
      <AssessmentSummaryUI
        loading={isLoading || isLoadingSummary}
        assessments={data}
        assessmentSummary={summary}
        pagination={{ page, perPage, setPage, setPerPage, total }}
      />
    </div>
  );
}
