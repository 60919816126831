import { Stack } from '@mui/material';
import React from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  required,
  useRecordContext,
} from 'react-admin';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import { careTeamRoles } from '../../constants/careTeamRoles';
import { get } from 'lodash';

const filterUserName = (searchText) => ({
  q: { field: 'user.full_name', value: searchText },
});

// todo - better success message
export const CareTeamCreateButton = ({ variant = 'default' }) => {
  const record = useRecordContext();

  const transform = (data) => {
    return { ...data, start_date: new Date().toISOString() };
  };

  let filters: any = {
    'role[ne]': `patient`,
    'is_provider[eq]': true,
    'tenant_id[in]': [get(record, 'user.tenant_id'), null],
  };

  return (
    <CreateInDialogButton
      title="Add new member to care team"
      resource={`patients/${record?.id}/care-teams`}
      transform={transform}
      variant={variant}
      label="Member"
      notifyMessage="Member added to care team"
      enableLogAction
      logActionLabel={(data) => {
        const careTeamRole = careTeamRoles.find(
          (role) => role.id === data.role,
        );
        const memberName = data?.practitioner?.full_name;
        return `Care team member "${memberName}" with role "${careTeamRole?.value}" added to care team`;
      }}
    >
      <Stack direction="column" gap={1}>
        <ReferenceInput
          source="practitioner_id"
          reference="users"
          filter={filters}
        >
          <AutocompleteInput
            label="Clinician"
            optionText={(choice) => {
              if (choice.is_internal) {
                return `${choice.first_name} ${choice.last_name} (Vironix) ${
                  choice.credentials ? `(${choice.credentials})` : ``
                }`;
              }
              return `${choice.full_name} ${
                choice.credentials ? `(${choice.credentials})` : ``
              }`;
            }}
            parse={(v) => (v === '' ? null : v)}
            helperText={false}
            validate={required()}
            filterToQuery={filterUserName}
          />
        </ReferenceInput>
        <SelectInput
          source="role"
          choices={careTeamRoles.filter((role) => role.canBeSelected !== false)}
          optionText="value"
          helperText={false}
          validate={required()}
        />
      </Stack>
    </CreateInDialogButton>
  );
};
