// This component should let users set tenant and location of devices.
import { Button } from '@/modules/ui/components/button';
import { TextInput } from '@/modules/ui/inputs/text-input';

import {
  passwordValidation,
  passwordsMatch,
} from '@/modules/login/utils/passwordValidation';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import React from 'react';
import { required, useCreate, useNotify } from 'react-admin';

import get from 'lodash/get';
import { FormProvider, useForm } from 'react-hook-form';

export const ChangePassword = () => {
  const [changePass, { isLoading }] = useCreate();
  const notify = useNotify();

  const form = useForm();

  const changePassword = (formData: any, e: any) => {
    changePass(
      'auth/change-password',
      {
        data: {
          old_password: formData.current_password,
          new_password: formData.new_password,
          new_password_2: formData.new_password_2,
        },
      },
      {
        onSuccess: () => {
          notify('Password successfully changed', { type: 'success' });
          form.reset();
        },
        onError: (error) => {
          let message = get(error, 'body.detail.0.msg');
          if (!message) message = get(error, 'message');
          notify(message, { type: 'error' });
        },
      },
    );
  };

  return (
    <Card className="max-w-lg w-full">
      <CardHeader>
        <CardTitle className="text-2xl">Change Password</CardTitle>
      </CardHeader>
      <CardContent>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(changePassword)}>
            <div className="flex flex-col gap-2">
              <TextInput
                source="current_password"
                validate={required()}
                type="password"
                autoComplete="current-password"
              />
              <TextInput
                source="new_password"
                validate={passwordValidation}
                type="password"
              />
              <TextInput
                source="new_password_2"
                label="Confirm password"
                type="password"
                validate={[required(), passwordsMatch()]}
              />
              <Button
                type="submit"
                disabled={isLoading}
                className="w-fit self-end"
              >
                {isLoading ? <LoadingSpinner /> : 'Update Password'}
              </Button>
            </div>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  );
};
