import { Alert, Card, CardContent, Container } from '@mui/material';
import { get } from 'lodash';
import React, { FC } from 'react';
import {
  ArrayInput,
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  RecordContextProvider,
  SaveContextProvider,
  SelectInput,
  SimpleFormIterator,
  useNotify,
  useRecordContext,
  useUpdate,
} from 'react-admin';
import { BaseForm } from '../../ra-ui/components/BaseForm';

export const PatientMonitoringConfig: FC = (props) => {
  const patientRecord = useRecordContext();

  const notify = useNotify();
  const [update, { isLoading: isUpdating }] = useUpdate();

  const transformData = (data) => {
    const sanitizedLimits = get(data, 'care_plan.monitoring_limits', []).map(
      (item) => {
        const sanitizedValues = {};
        Object.keys(item).forEach((key) => {
          if (item[key] === '') {
            sanitizedValues[key] = null;
          } else {
            // this is a non-empty value, so we include it in the sanitized item
            sanitizedValues[key] = item[key];
          }
        });
        return sanitizedValues;
      },
    );

    return {
      care_plan: {
        use_org_measurement_limits: data.care_plan.use_org_measurement_limits,
        monitoring_limits: sanitizedLimits,
      },
    };
  };

  const save = (data) => {
    update(
      'patients',
      {
        id: patientRecord.id,
        data: transformData(data),
        previousData: patientRecord,
      },
      {
        onSuccess: (data) => notify('Changes saved'),
        onError: (error) =>
          notify(`Unable to save changes: ${get(error, 'message')}`, {
            type: 'warning',
          }),
      },
    );
  };

  if (!patientRecord) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-2">
      <div>
        <h3 className="text-xl font-semibold">Monitoring Configuration</h3>
        <p className="text-sm text-gray-500">
          Configure monitoring limits specific to this patient. When a
          measurement is outside of the configured limits, the metric will be
          highlighted on the metrics page.
        </p>
      </div>

      <Card>
        <CardContent>
          <RecordContextProvider value={patientRecord}>
            <SaveContextProvider
              value={{ save, saving: isUpdating, mutationMode: 'pessimistic' }}
            >
              <Container>
                <BaseForm
                  padding={1}
                  warnWhenUnsavedChanges
                  sanitizeEmptyValues
                >
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      get(formData, 'care_plan.use_org_measurement_limits') ===
                        true && (
                        <Alert severity="info" sx={{ my: 2 }}>
                          Currently using monitoring limits defined globally by
                          your organization. Toggle the button below to set
                          patient-specific limits.
                        </Alert>
                      )
                    }
                  </FormDataConsumer>
                  <BooleanInput
                    source="care_plan.use_org_measurement_limits"
                    label="Use organization limits"
                  />{' '}
                  <FormDataConsumer>
                    {({ formData, ...rest }) => (
                      <ArrayInput
                        source={`care_plan.monitoring_limits`}
                        disabled={
                          get(
                            formData,
                            'care_plan.use_org_measurement_limits',
                          ) === true
                        }
                      >
                        <SimpleFormIterator inline>
                          <SelectInput
                            source="parameter"
                            choices={[
                              {
                                id: 'oxygen_saturation',
                                name: 'Oxygen Saturation',
                              },
                              { id: 'heart_rate', name: 'Heart Rate' },
                              {
                                id: 'blood_pressure',
                                name: 'Blood Pressure',
                              },
                              { id: 'body_temperature', name: 'Temperature' },
                            ]}
                          />

                          <FormDataConsumer>
                            {({ scopedFormData, getSource, ...rest }) =>
                              scopedFormData.parameter &&
                              scopedFormData.parameter === 'blood_pressure' && (
                                <SelectInput
                                  required
                                  source={getSource('sub_parameter')}
                                  parse={(v) => (v === '' ? null : v)}
                                  choices={[
                                    { id: 'systolic', name: 'Systolic' },
                                    { id: 'diastolic', name: 'Diastolic' },
                                  ]}
                                />
                              )
                            }
                          </FormDataConsumer>

                          <NumberInput source="min" />
                          <NumberInput source="max" />
                        </SimpleFormIterator>
                      </ArrayInput>
                    )}
                  </FormDataConsumer>
                </BaseForm>
              </Container>
            </SaveContextProvider>
          </RecordContextProvider>
        </CardContent>
      </Card>
    </div>
  );
};
