import ProviderField from '@/modules/ra-ui/components/ProviderField';
import AppTitle from '@/modules/layout/components/app-title';
import { DataTableFilter } from '@/modules/data-table/components/data-table-filter';
import DataTableProviderFilter from '@/modules/data-table/components/data-table-provider-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import DataTableDateRangeFilter from '@/modules/data-table/components/date-table-date-range-filter';
import { cptCodeInfo } from '@/modules/patients/constants/cptCodeInfo';
import { Menu, MenuItem } from '@mui/material';
import React, { FC } from 'react';
import {
  BooleanField,
  BulkDeleteWithConfirmButton,
  Button,
  DatagridConfigurable,
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField,
  useListContext,
  useUnselectAll,
  useUpdateMany,
} from 'react-admin';
import { CPTCodeField } from '@/modules/ra-ui/components/CPTCodeField';
import { CreateClaimItemDialog } from '../../modules/claims/components/CreateClaimItem';
import { EditClaimItemButton } from '../../modules/claims/components/EditClaimItem';
import { ExportClaimItemsButton } from '../../modules/claims/components/ExportClaimItemsButton';
import { DataTableFilterSingle } from '@/modules/data-table/components/data-table-filter-single';

const BulkChangeSubmittedButton = () => {
  const { selectedIds, resource } = useListContext();
  const unselectAll = useUnselectAll(resource);

  const [updateMany, { isLoading }] = useUpdateMany();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = (value) => {
    updateMany(
      'claim-items',
      {
        ids: selectedIds,
        data: { submitted: value },
      },
      {
        onSuccess: () => {
          unselectAll();
        },
      },
    );

    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        label="Mark Submitted"
        disabled={isLoading}
      />
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem key={1} onClick={() => handleSelectOption(true)}>
          True
        </MenuItem>
        <MenuItem key={2} onClick={() => handleSelectOption(false)}>
          False
        </MenuItem>
      </Menu>
    </div>
  );
};

const Toolbar = () => (
  <DataTableToolbar
    showViewOptions={false}
    createButton={<CreateClaimItemDialog />}
    actionButtons={[<ExportClaimItemsButton />]}
  >
    <DataTableProviderFilter column="tenant_id[eq]" title="Provider" />
    <DataTableFilter
      column="cpt_code[in]"
      title="CPT Code"
      options={Object.keys(cptCodeInfo).map((code) => ({
        label: code,
        value: code,
      }))}
    />
    <DataTableDateRangeFilter
      column="suggested_billing_date"
      title="Date range"
    />
    <DataTableDateRangeFilter column="created_on" title="Created On" dateOnly />
    <DataTableFilterSingle
      column="submitted[eq]"
      title="Submitted"
      options={[
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]}
    />
  </DataTableToolbar>
);

export const ClaimItemList: FC = (props) => {
  return (
    <>
      <AppTitle title="Claims" />
      <List
        {...props}
        actions={<Toolbar />}
        storeKey={false}
        sort={{
          field: null,
          order: null,
        }}
        empty={
          <>
            <div className="max-full items-center mx-auto d-flex justify-content-center align-items-center">
              <div className="flex flex-col items-center justify-center h-full gap-5">
                <p className="font-bold text-xl">No claims found</p>
                <p className="text-gray-500 text-md">
                  Claims are automatically added when a service is completed and
                  can be billed for.
                </p>
                <CreateClaimItemDialog />
              </div>
            </div>
          </>
        }
      >
        <DatagridConfigurable
          rowClick={null}
          bulkActionButtons={
            <>
              <BulkChangeSubmittedButton />
              <BulkDeleteWithConfirmButton mutationMode="optimistic" />
            </>
          }
          omit={['end_date_of_service']}
        >
          <ReferenceField
            label="Patient"
            source="patient_id"
            reference="users"
            link={(record) => `/patient-queue/${record.id}/show#services`}
            sortable={false}
          >
            <TextField source="full_name" />
          </ReferenceField>
          <ProviderField label="Provider" />
          <DateField source="suggested_billing_date" />
          <DateField source="date_of_service" />
          <DateField source="end_date_of_service" />
          <DateField source="created_on" />
          <CPTCodeField label="CPT Code" source="cpt_code" />
          <NumberField source="units" label="Units" />
          <BooleanField source="submitted" label="Submitted" />
          <EditClaimItemButton />
        </DatagridConfigurable>
      </List>
    </>
  );
};
