import { Button } from '@/modules/ui/components/button';
import { FileDown, Loader2 } from 'lucide-react';
import React from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';

// MUI dialog for export
export const ExportReportButton = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const record = useRecordContext();
  const dataprovider = useDataProvider();

  const onExport = (values) => {
    setIsLoading(true);

    dataprovider
      .getFile(`reports/${record.id}/export`, {
        download: '',
        downloadType: 'blob',
      })
      .then(() => {
        return new Promise((resolve) => setTimeout(resolve, 500));
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <Button onClick={onExport} size="sm" className="h-8" variant="default">
      {isLoading ? (
        <>
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Please wait
        </>
      ) : (
        <>
          <FileDown className="mr-2 h-4 w-4 " />
          Export Report
        </>
      )}
    </Button>
  );
};
