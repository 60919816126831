import { Button } from '@/modules/ui/components/button';
import { useCreate, useNotify, useRecordContext, useRefresh } from 'ra-core';
import React from 'react';
import { get } from 'lodash';

const PasswordResetButton = ({ className = null }) => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const [create, { isLoading }] = useCreate();

  const handleClick = () => {
    create(`users/${record.id}/reset-password`, null, {
      onSuccess: (data) => {
        notify(
          'Successfully reset user password. They will receive an email with instructions.',
          { type: 'success' },
        );
        refresh();
      },
      onError: (error) =>
        notify(`${get(error, 'message')}`, {
          type: 'warning',
        }),
    });
  };

  return (
    <Button
      disabled={isLoading}
      variant="outline"
      onClick={handleClick}
      className={className}
      type="button"
    >
      Reset Password
    </Button>
  );
};

export default PasswordResetButton;
