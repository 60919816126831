import RangeField from '@/modules/ra-ui/components/RangeField';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { get } from 'lodash';
import { Pill } from 'lucide-react';
import React from 'react';
import {
  Datagrid,
  FunctionField,
  Pagination,
  TextField,
  useGetList,
  useRecordContext,
  ListContextProvider,
} from 'react-admin';
import { MedicationDeleteButton } from './MedicationDeleteButton';
import { MedicationEditButton } from './MedicationEditButton';
import TabbedWrapper from '@/modules/ra-ui/components/tabbed-wrapper';

export const MedicationsList = () => {
  const record = useRecordContext();

  const sort = { field: null, order: null };
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(20);
  const [selectedTab, setSelectedTab] = React.useState('all');
  const [filterValues, setFilters] = React.useState({});

  const { data, total, isLoading } = useGetList(
    `patients/${record?.id}/medication-administrations`,
    {
      pagination: { page, perPage },
      sort,
      filter: {
        ...filterValues,
        ...(selectedTab !== 'all' && { 'source[eq]': selectedTab }),
      },
    },
    {
      enabled: !!record,
    },
  );

  if (!record) {
    return null;
  }

  const handleTabChange = (value) => {
    setSelectedTab(value);
    setPage(1);
  };

  return (
    <div className="w-full overflow-x-auto">
      <ListContextProvider
        // @ts-ignore
        value={{ filterValues, setFilters, displayedFilters: {} }}
      >
        <TabbedWrapper
          options={[
            { value: 'all', href: 'all', title: 'All' },
            { value: 'vironix', href: 'vironix', title: 'Vironix' },
            { value: 'HIE-TH', href: 'hie', title: 'HIE' },
          ]}
          selectedTab={selectedTab}
          setSelectedTab={handleTabChange}
        >
          <Datagrid
            data={data}
            total={total}
            isLoading={isLoading}
            sort={sort}
            bulkActionButtons={false}
            empty={
              <EmptyDatagrid
                resourceName="Medications"
                icon={<Pill />}
                textFormatString="No medications found"
              />
            }
          >
            <TextField source="medication.name" label="Medication" />
            <TextField source="dosage.dose" label="Dosage" />
            <FunctionField
              render={(record) => {
                if (
                  get(record, 'dosage.rate.numerator') &&
                  get(record, 'dosage.rate.denominator')
                ) {
                  return `${record.dosage.rate.numerator} x ${record.dosage.rate.denominator}`;
                } else {
                  return '';
                }
              }}
              label="Frequency"
            />
            <BadgeField source="status" />
            <RangeField
              source_start="start_date"
              source_end="end_date"
              sortBy="start_date"
              label="Timestamp"
            />
            <BadgeField source="source" />
            <FunctionField
              render={(record) =>
                record.status === 'in-progress' &&
                record.source === 'vironix' && <MedicationEditButton label="" />
              }
            />
            <FunctionField
              render={(record) =>
                record.status === 'in-progress' &&
                record.source === 'vironix' && <MedicationDeleteButton />
              }
            />
          </Datagrid>
          {total > 10 ? (
            <Pagination
              total={total}
              perPage={perPage}
              page={page}
              setPage={setPage}
              setPerPage={setPerPage}
            />
          ) : null}
        </TabbedWrapper>
      </ListContextProvider>
    </div>
  );
};
