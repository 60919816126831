import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { Button } from '@/modules/ui/components/button';
import React, { cloneElement } from 'react';
import {
  BooleanInput,
  Datagrid,
  DateInput,
  Form,
  FunctionField,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  maxValue,
  minValue,
  required,
  useCreate,
  useDelete,
  useGetList,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { useFormState } from 'react-hook-form';
import RefreshSubscriptionButton from './RefreshSubscriptionButton';

import { SaveButton } from '@/modules/ra-ui/components/save-button';
import { Card, CardContent, CardFooter } from '@/modules/ui/components/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';
import { get } from 'lodash';
import {
  FileDown,
  Loader2,
  Plus,
  Receipt,
  ScanSearch,
  Trash2,
} from 'lucide-react';
import { createPortal } from 'react-dom';
import ConfirmActionButton from '@/modules/ui/components/confirm-action-button';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import { FeeForServiceList } from './FeeForServiceList';

const formatPriceLabel = (price) => {
  const price_id_last_four = price.id.slice(-4);
  if (get(price, 'recurring.interval') === 'month') {
    return `${price_id_last_four} - $${
      price.unit_amount / 100
    } ${price.currency.toUpperCase()} / month`;
  }
  return `${price_id_last_four} - $${
    price.unit_amount / 100
  } ${price.currency.toUpperCase()}`;
};

const CreateFeeForServiceButton = ({ isHybrid }) => {
  const record = useRecordContext();

  return (
    <CreateInDialogButton
      resource={`providers/${record?.id}/fee-for-service-pricing`}
      title="Create Fee for Service Pricing"
      variant="default"
      label="Create Fee for Service Pricing"
      transform={(data) => {
        if (isHybrid) {
          data.hybrid_pricing = true;
        }
        return data;
      }}
    >
      <div className="flex flex-col gap-1">
        <NumberInput
          source="proportion"
          helperText={false}
          validate={[required(), minValue(0), maxValue(1.0)]}
        />
        <TextInput
          source="specific_locality_id_override"
          defaultValue={null}
          helperText={false}
        />
        <DateInput source="effective_date" helperText={false} />
        <BooleanInput source="clear_existing" defaultChecked={false} />
      </div>
    </CreateInDialogButton>
  );
};

const PreviewInvoiceDialog = ({
  handleClose,
  onSubmit,
  open,
  isLoading,
  data,
  registeredOn,
  isLoadingSubmit,
}) => {
  const monthYearSelector = () => {
    const options = [];
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() + 1; // getMonth() is zero-indexed
    const registeredDate = new Date(registeredOn);
    let registeredYear = registeredDate.getFullYear();
    let registeredMonth = registeredDate.getMonth() + 1;

    while (
      currentYear > registeredYear ||
      (currentYear === registeredYear && currentMonth >= registeredMonth)
    ) {
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      options.push({
        id: `${currentMonth}/${currentYear}`,
        name: `${monthNames[currentMonth - 1]} ${currentYear}`,
      });
      currentMonth--;
      if (currentMonth === 0) {
        currentMonth = 12;
        currentYear--;
      }
    }

    return options;
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogContent
        onClose={handleClose}
        className="overflow-y-auto max-h-[90vh]"
      >
        <DialogHeader>
          <DialogTitle>Preview Invoice</DialogTitle>
          <DialogDescription>
            Preview the invoice for the selected month and year.
          </DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit}>
          <div className="flex flex-col justify-between gap-2">
            <div className="flex flex-col gap-2">
              <SelectInput
                label="Month and Year"
                source="month-year"
                helperText={false}
                choices={monthYearSelector()}
                validate={required()}
              />
              <DateInput
                source="due_date"
                helperText={false}
                defaultValue={null}
              />
              <BooleanInput
                source="finalize_invoice"
                defaultChecked={false}
                helperText={false}
              />
              <SelectInput
                source="vendor"
                choices={[{ id: 'stripe', name: 'Stripe' }]}
                defaultValue="stripe"
                helperText={false}
                validate={required()}
              />
            </div>
            <div className="flex flex-row gap-2 justify-end">
              <SubmitButton
                isLoading={isLoading && !isLoadingSubmit}
                text="Preview"
                icon={<ScanSearch />}
                id="preview-invoice"
              />
              {
                <SubmitButton
                  isLoading={isLoadingSubmit}
                  text="Submit"
                  icon={<Receipt />}
                  id="submit-invoice"
                  disabled={data === null}
                />
              }
            </div>
          </div>
        </Form>
        {data && (
          <div className="m-4">
            <h4 className="text-lg font-semibold mb-4 border-b pb-2">
              Line Items
            </h4>
            <div className="my-4 p-4">
              <div className="overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        Description
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Amount ($)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.line_items.map((item, index) => (
                      <tr key={index} className="bg-white border-b">
                        <td className="py-4 px-6">{item.description}</td>
                        <td className="py-4 px-6">
                          {parseFloat(item.amount).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <td className="py-3 px-6 font-semibold">Total</td>
                      <td className="py-3 px-6 font-semibold">
                        {parseFloat(
                          data.line_items.reduce(
                            (sum, item) => sum + item.amount,
                            0,
                          ),
                        ).toFixed(2)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <br />
            <h4 className="text-lg font-semibold mb-4 border-b pb-2">
              Summary by Patient
            </h4>
            <div className="my-4 p-4  ">
              <div className="overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        Patient Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Claims completed (#)
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Approximate Reimbursement ($)
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Vironix Charge ($)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.summary.map((row, index) => (
                      <tr key={index} className="bg-white border-b">
                        <td className="py-4 px-6">{row['Patient Name']}</td>
                        <td className="py-4 px-6">{row['count']}</td>
                        <td className="py-4 px-6">
                          {parseFloat(row['total_fee_schedule_price']).toFixed(
                            2,
                          )}
                        </td>
                        <td className="py-4 px-6">
                          {parseFloat(row['total_price']).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <td className="py-3 px-6 font-semibold">Total</td>
                      <td className="py-3 px-6 font-semibold">
                        {data.totals['count']}
                      </td>
                      <td className="py-3 px-6 font-semibold">
                        {parseFloat(
                          data.totals['total_fee_schedule_price'],
                        ).toFixed(2)}
                      </td>
                      <td className="py-3 px-6 font-semibold">
                        {parseFloat(data.totals['total_price']).toFixed(2)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <br />
            <h4 className="text-lg font-semibold mb-4 border-b pb-2">
              Detailed Breakdown
            </h4>
            {data.detailed_breakdown.map((patient, patientIndex) => (
              <div key={patientIndex} className="mb-4 p-4 ">
                <h5 className="text-lg font-semibold text-gray-700">
                  {patient['patient_name']}{' '}
                  <span className="text-sm text-gray-500">
                    {patient['patient_id'] ? `(${patient['patient_id']})` : ''}
                  </span>
                </h5>
                <div className="mb-2 grid grid-cols-3 gap-4 text-sm">
                  <div>
                    <span className="font-semibold">First Billable Date:</span>{' '}
                    {patient['first_billable_date']
                      ? new Date(
                          patient['first_billable_date'],
                        ).toLocaleDateString()
                      : 'N/A'}
                  </div>
                  <div>
                    <span className="font-semibold">
                      First Device Activity:
                    </span>{' '}
                    {patient['first_device_activity']
                      ? new Date(
                          patient['first_device_activity'],
                        ).toLocaleDateString()
                      : 'N/A'}
                  </div>
                  <div>
                    <span className="font-semibold">Billable:</span>{' '}
                    {!patient['should_not_bill'] ? 'Yes' : 'No'}
                  </div>
                </div>
                <div className="mt-2">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          CPT Code
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Units
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Approximate Reimbursement ($)
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Vironix Charge ($)
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {patient['items'].map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {row['cpt_code']}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {row['units']}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {row['fee_schedule_price']
                              ? `$${parseFloat(row['fee_schedule_price']).toFixed(2)}`
                              : 'N/A'}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {row['price']
                              ? `$${parseFloat(row['price']).toFixed(2)}`
                              : 'N/A'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot className="bg-gray-50">
                      <tr>
                        <td className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase">
                          Total
                        </td>
                        <td></td>
                        <td className="px-6 py-3 text-left text-xs font-semibold text-gray-600">
                          $
                          {parseFloat(
                            patient['total_fee_schedule_price'],
                          ).toFixed(2)}
                        </td>
                        <td className="px-6 py-3 text-left text-xs font-semibold text-gray-600">
                          ${parseFloat(patient['total_price']).toFixed(2)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            ))}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

const PreviewInvoiceButton = () => {
  const record = useRecordContext();
  const [open, setOpen] = React.useState(false);
  const [create, { isLoading }] = useCreate();
  const [isLoadingSubmit, setIsLoadingSubmit] = React.useState(false);
  const notify = useNotify();
  const [data, setData] = React.useState(null);
  const refresh = useRefresh();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setData(null);
    setIsLoadingSubmit(false);
    setOpen(false);
  };

  const onSubmit = (values, e) => {
    let isPreview = true;
    if (e.nativeEvent.submitter.id === 'submit-invoice') {
      isPreview = false;
      setIsLoadingSubmit(true);
    }

    const month = values['month-year'].split('/')[0];
    const year = values['month-year'].split('/')[1];

    create(
      `providers/${record?.id}/fee-for-service-invoice`,
      {
        data: {
          month: month,
          year: year,
          is_preview: isPreview,
          due_date: values.due_date,
        },
      },
      {
        onSuccess: (response) => {
          setData(response);
          if (!isPreview) {
            handleClose();
            setIsLoadingSubmit(false);
            refresh();
          }
        },
        onError: (error) => {
          notify((error as Error)?.message, { type: 'error' });
          setIsLoadingSubmit(false);
        },
      },
    );
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        size="sm"
        className="h-8"
        variant="outline"
        type="button"
      >
        <Plus className="mr-2 h-4 w-4" />
        New Invoice
      </Button>
      {createPortal(
        <PreviewInvoiceDialog
          handleClose={handleClose}
          onSubmit={onSubmit}
          open={open}
          isLoading={isLoading}
          isLoadingSubmit={isLoadingSubmit}
          data={data}
          registeredOn={record?.registered_on}
        />,
        document.body,
      )}
    </>
  );
};

const SubmitButton = ({
  isLoading,
  text,
  icon = <FileDown />,
  disabled = false,
  ...rest
}) => {
  const { isValid, isValidating, isSubmitting } = useFormState();

  const disabledState =
    isValidating || isSubmitting || !isValid || isLoading || disabled;

  return (
    <Button disabled={disabledState} type="submit" className="mt-2" {...rest}>
      {isLoading ? (
        <>
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Please wait
        </>
      ) : (
        <>
          {cloneElement(icon, { className: 'mr-2 h-4 w-4' })}
          {text}
        </>
      )}
    </Button>
  );
};

const InvoicesList = () => {
  const record = useRecordContext();

  const sort = { field: null, order: null };

  const { data, total, isLoading } = useGetList(
    `providers/${record?.id}/invoices`,
    {
      pagination: { page: 1, perPage: 20 },
      filter: {},
      sort: sort,
    },
  );
  if (!record) {
    return null;
  }

  return (
    <Datagrid
      data={data}
      total={total}
      isLoading={isLoading}
      sort={sort}
      bulkActionButtons={false}
      setSort={null}
      empty={
        <EmptyDatagrid
          resourceName="Invoices"
          textFormatString="No invoices found for this provider"
        />
      }
    >
      <FunctionField
        render={(record) => `$   ${(record.total / 100).toFixed(2)}`}
        label="Amount Due"
      />
      <TextField source="number" />
      <BadgeField source="status" />
      <LuxonDateField source="created" showTime={false} label="Date" />
      <FunctionField
        render={(record) => (
          <>
            {record.hosted_invoice_url && (
              <a
                href={record.hosted_invoice_url}
                target="_blank"
                rel="noreferrer"
              >
                <Button className="h-8 mr-2" variant="outline">
                  Invoice
                </Button>
              </a>
            )}
            {record.detailed_pdf && (
              <a href={record.detailed_pdf} target="_blank" rel="noreferrer">
                <Button className="h-8" variant="outline">
                  Detailed
                </Button>
              </a>
            )}
          </>
        )}
      />
    </Datagrid>
  );
};

export const BillingSection = () => {
  const record = useRecordContext();
  const [deleteOne, { isLoading }] = useDelete();
  const refresh = useRefresh();
  const notify = useNotify();

  if (!record) {
    return null;
  }

  const clearSubscriptions = () => {
    deleteOne(
      `providers/${record?.id}`,
      {
        id: 'subscription',
      },
      {
        onSuccess: () => {
          refresh();
          notify('Subscriptions cleared', { type: 'info' });
        },
        onError(error) {
          notify((error as Error)?.message, { type: 'error' });
        },
      },
    );
  };

  return (
    <div className="flex flex-col gap-4 mb-6">
      <Card>
        <Form warnWhenUnsavedChanges sanitizeEmptyValues>
          <CardContent className="mt-4">
            <div>
              <p className="text-md font-medium">Billing Scheme</p>
              <div className="flex flex-row  space-x-4 items-center">
                <SelectInput
                  source="billing_scheme"
                  helperText={false}
                  validate={required()}
                  choices={[
                    { id: 'subscription', name: 'Subscription' },
                    { id: 'fee_for_service', name: 'Fee for Service' },
                    {
                      id: 'hybrid_fee_for_service',
                      name: 'Hybrid Fee for Service',
                    },
                  ]}
                />
                {record?.stripe_subscription_id &&
                  record?.billing_scheme !== 'subscription' && (
                    <ConfirmActionButton
                      action={clearSubscriptions}
                      actionText="Clear Subscriptions"
                      confirmText="Clear Subscriptions"
                      Icon={Trash2}
                      cancelText="Cancel"
                      dialogTitle="Are you sure you want to clear all subscriptions?"
                      dialogDescription="This will remove all subscriptions for this provider. This action cannot be undone."
                      isLoading={isLoading}
                      buttonVariant="destructive"
                      buttonType="button"
                    />
                  )}
              </div>
            </div>
            <CardFooter className="float-right mt-2">
              <SaveButton variant="default" className="h-8" />
            </CardFooter>
          </CardContent>
        </Form>
      </Card>

      <Card>
        <Form warnWhenUnsavedChanges sanitizeEmptyValues>
          <CardContent className="mt-4">
            <div>
              <p className="text-md font-medium">Stripe</p>
              <div className="flex flex-1 space-x-4 items-center">
                <BooleanInput
                  source="stripe_billing_enabled"
                  label="Enabled"
                  helperText={false}
                />
                <TextInput
                  source="stripe_customer_id"
                  label="Stripe Customer ID"
                  helperText={false}
                />
              </div>
            </div>
            <CardFooter className="float-right mt-2">
              <SaveButton variant="default" className="h-8" />
            </CardFooter>
          </CardContent>
        </Form>
      </Card>

      <Card>
        <CardContent className="mt-4">
          <div className="flex flex-col gap-2 ">
            <p className="text-lg font-medium">Invoices</p>
            {record?.billing_scheme === 'fee_for_service' ||
            record?.billing_scheme === 'hybrid_fee_for_service' ? (
              <div className="flex flex-1 space-x-4 items-center">
                <PreviewInvoiceButton />
              </div>
            ) : null}
            <InvoicesList />
          </div>
        </CardContent>
      </Card>
      {record?.billing_scheme === 'subscription' && (
        <Card>
          <CardContent className="mt-4">
            <Form warnWhenUnsavedChanges sanitizeEmptyValues>
              <div className="flex flex-col gap-2">
                <div>
                  <p className="text-md font-medium">Subscription Pricing </p>
                  <p className="text-sm">
                    Keep blank to use default prices for each product on stripe.
                  </p>
                  <p className="text-sm">
                    Configure options by adding prices in stripe for each
                    product.
                  </p>
                </div>
                <div className="flex flex-1 space-x-4 items-center">
                  {['simple', 'complex', 'software_only', 'ccm_only'].map(
                    (item) => (
                      <ReferenceInput
                        source={`stripe_${item}_price_id`}
                        reference={`billing/product-prices/${item}`}
                      >
                        <SelectInput
                          helperText={false}
                          optionText={formatPriceLabel}
                        />
                      </ReferenceInput>
                    ),
                  )}
                </div>
                <div className="flex flex-1 space-x-4 items-center mt-2">
                  <p>Manually refresh customer's subscription</p>
                  <RefreshSubscriptionButton />
                </div>
              </div>
              <CardFooter className="float-right mt-2">
                <SaveButton variant="default" className="h-8" />
              </CardFooter>
            </Form>
          </CardContent>
        </Card>
      )}

      <Card>
        <CardContent className="mt-4">
          <Form warnWhenUnsavedChanges sanitizeEmptyValues>
            <div className="flex flex-col gap-2">
              <div>
                <p className="text-md font-medium">
                  Patient Initiation Fee Pricing{' '}
                </p>
                <p className="text-sm">
                  Keep blank to use default prices for each product on stripe.
                </p>
                <p className="text-sm">
                  Configure options by adding prices in stripe for each product.
                </p>
              </div>
              <div className="flex flex-1 space-x-4 items-center">
                <ReferenceInput
                  source={`stripe_initiation_fee_price_id`}
                  reference={`billing/product-prices/initiation_fee`}
                >
                  <SelectInput
                    helperText={false}
                    optionText={formatPriceLabel}
                  />
                </ReferenceInput>
              </div>
            </div>

            <CardFooter className="float-right mt-2">
              <SaveButton variant="default" className="h-8" />
            </CardFooter>
          </Form>
        </CardContent>
      </Card>

      {record?.billing_scheme === 'fee_for_service' ||
      record?.billing_scheme === 'hybrid_fee_for_service' ? (
        <Card>
          <CardContent className="mt-4">
            <div className="flex flex-col gap-2 ">
              <p className="text-lg font-medium">
                Fee for Service Pricing Table
              </p>
              <div className="flex flex-1 space-x-4 items-center">
                <CreateFeeForServiceButton
                  isHybrid={record?.billing_scheme === 'hybrid_fee_for_service'}
                />
              </div>
              <FeeForServiceList />
            </div>
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
};
