import React from 'react';
import { TextInput, useRecordContext } from 'react-admin';
import { DateTime } from 'luxon';
import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import { Button } from '@/modules/ui/components/button';

export function MarkDeclinedButton() {
  const record = useRecordContext();

  const transform = (data) => {
    return {
      patient_status: 'declined',
      declined_on: DateTime.now().toISODate(),
      declined_reason: data.declined_reason,
    };
  };

  if (!record) return null;

  if (record.patient_status === 'disabled') return null;

  return (
    <EditInDialogButton
      title="Mark patient as declined"
      resource={`patients`}
      transform={transform}
      notifyMessage="Patient set as declined"
      refreshOnSuccess={true}
      editButton={
        <Button className=" w-fit mx-auto" variant="outline">
          Mark Declined
        </Button>
      }
      saveButtonAlwaysEnable
    >
      <div className="flex flex-col gap1">
        <TextInput source="declined_reason" label="Reason" helperText={false} />
      </div>
    </EditInDialogButton>
  );
}
