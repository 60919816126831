// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HistoryIcon from '@mui/icons-material/History';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { find, get } from 'lodash';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next'
import { useRecordContext } from 'react-admin';

export interface VitalConfig {
  measureReportName: string;
  chartMeasureName?: string;
  nameKey: string;
  roundDigits?: number;
  alwaysShow?: boolean;
  faIcon?: any;
}

export interface VitalCardBaseProps {
  value: any;
  text: string;
  chartName?: string;
  unit?: string;
  lastUpdated?: string;
  baseline?: any;
  faIcon?: string | any;
  handleIndicatorClick?: any;
  limits?: any;
  roundDigits?: number;
  onClick?: any;
}

export const VitalCardBase = ({
  value,
  text,
  chartName,
  unit,
  lastUpdated,
  baseline,
  faIcon,
  handleIndicatorClick,
  limits,
  roundDigits,
  onClick,
}: VitalCardBaseProps) => {
  const patientRecord = useRecordContext();
  const [highlight, setHighlight] = useState(false);
  const formatTrendArrow = () => {
    const current = formatVitalValue(value);
    const before = formatVitalValue(baseline);

    const delta = Number(current) - Number(before);
    if (Math.abs(delta) < Number(current) * 0.01) {
      return (
        <ArrowForwardIcon
          fontSize="large"
          sx={{ height: '1em', width: 'auto' }}
        />
      );
    } else if (delta > 0) {
      return (
        <ArrowUpwardIcon
          fontSize="large"
          sx={{ height: '1em', width: 'auto' }}
        />
      );
    } else if (delta < 0) {
      return (
        <ArrowDownwardIcon
          fontSize="large"
          sx={{ height: '1em', width: 'auto' }}
        />
      );
    }
  };

  const formatUpdateChip = () => {
    const dateToday = DateTime.local();
    const assessmentTime = DateTime.fromISO(lastUpdated, {
      zone: 'utc',
    }).setZone('local');

    const diff = dateToday.diff(assessmentTime, ['minutes', 'hours', 'days']);

    let text;

    if (diff.days >= 2) {
      text = `${diff.days.toFixed(0)} days ago`;
    } else if (diff.days >= 1 && diff.days < 2) {
      text = `${diff.days.toFixed(0)} day ago`;
    } else if (diff.hours < 24 && diff.hours >= 1) {
      text = `${diff.hours.toFixed(0)} hours ago`;
    } else if (diff.minutes < 60 && diff.minutes > 5) {
      text = `${diff.minutes.toFixed(0)} minutes ago`;
    } else if (diff.minutes <= 5) {
      text = 'Just now';
    }

    return <Chip label={text} sx={{ color: 'black' }} />;
  };

  const formatVitalValue = (input) => {
    if (!input || !get(input, 'value')) {
      return '';
    }

    if (chartName === 'blood_pressure') {
      const systolic =
        roundDigits !== null
          ? input.value.systolic.toFixed(roundDigits)
          : input.value.systolic;
      const diastolic =
        roundDigits !== null
          ? input.value.diastolic.toFixed(roundDigits)
          : input.value.diastolic;

      return `${systolic} / ${diastolic}`;
    }

    const referenceValues = {
      forced_expiratory_volume_1: 'fvc_GLI2012',
      peak_expiratory_flow_rate: 'pef_NHANES',
    };

    const chartReferenceKey = referenceValues[chartName];
    if (
      chartReferenceKey &&
      get(
        patientRecord,
        `patient.profile.reference_values.${chartReferenceKey}.value`,
      )
    ) {
      const value = input.value;
      const referenceValue = get(
        patientRecord,
        `patient.profile.reference_values.${chartReferenceKey}.value`,
      );
      const percentPredicted = (value / referenceValue) * 100;
      return `${value} (${percentPredicted.toFixed(0)}%)`;
    }

    const rounded =
      roundDigits !== null ? input.value.toFixed(roundDigits) : input.value;

    return rounded;
  };

  const formatLimitChip = () => {
    // const limit = filter(limits, (o) => o.parameter === chartName);

    let limitChip = null;

    find(limits, (limit) => {
      if (limit.parameter === chartName) {
        const currentValue = limit.sub_parameter
          ? get(value, ['value', limit.sub_parameter])
          : get(value, 'value');

        if (limit.max && currentValue >= limit.max) {
          if (!highlight) setHighlight(true);
          limitChip = (
            <Grid item alignSelf="flex-start">
              <Typography
                variant="h6"
                component="sub"
                sx={{ backgroundColor: `rgba(255, 255, 0, 0.5)` }}
              >
                H
              </Typography>
            </Grid>
          );
          return true;
        }
        if (limit.min && currentValue <= limit.min) {
          if (!highlight) setHighlight(true);
          limitChip = (
            <Grid item alignSelf="flex-end">
              <Typography
                variant="h6"
                component="sub"
                sx={{ backgroundColor: `rgba(255, 255, 0, 0.5)` }}
              >
                L
              </Typography>
            </Grid>
          );
          return true;
        }
      }
    });

    return limitChip;
  };

  const formatValue = () => {
    const chip = formatLimitChip();
    return (
      <Tooltip title={chip ? 'Measurement is outside limits' : ''}>
        <Grid container alignItems="flex-start">
          {chip}
          <Grid item>
            <Typography variant="h5" component="div">
              <Box
                fontWeight={500}
                sx={chip ? { backgroundColor: `rgba(255, 255, 0, 0.5)` } : null}
              >
                {formatVitalValue(value)}
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Tooltip>
    );
  };

  const formatDate = () => {
    if (lastUpdated) {
      const dateToday = DateTime.local().startOf('day');
      const assessmentTime = DateTime.fromISO(lastUpdated, {
        zone: 'utc',
      })
        .setZone('local')
        .startOf('day');

      const diff = dateToday.diff(assessmentTime, 'days').days;

      if (diff >= 1) {
        return DateTime.fromISO(lastUpdated, {
          zone: 'utc',
        })
          .setZone('local')
          .toLocaleString(DateTime.DATETIME_SHORT);
      } else {
        return (
          'Today, ' +
          DateTime.fromISO(lastUpdated, {
            zone: 'utc',
          })
            .setZone('local')
            .toLocaleString(DateTime.TIME_SIMPLE)
        );
      }
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        border: highlight ? '3px solid rgba(255, 255, 0, 1)' : null,
        maxWidth: 'max-content',
      }}
      onClick={onClick}
    >
      <CardActionArea onClick={handleIndicatorClick} disabled={!chartName}>
        <CardContent>
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent="space-between"
          >
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={0}
              >
                <Grid item style={{ marginBottom: '8px' }}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      {faIcon ? (
                        <>
                          {/* <FontAwesomeIcon
                                                        icon={faIcon}
                                                    /> */}
                        </>
                      ) : null}
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle2" component="div">
                        <Box fontWeight={600}>
                          {text} {unit ? ' (' + unit + ')' : null}
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <HistoryIcon fontSize="small" />
                        </Grid>
                        <Grid item>
                          <Typography variant="body2">
                            {formatDate()}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>{formatValue()}</Grid>
                  </Grid>
                </Grid>

                {baseline ? (
                  <>
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                              <Typography variant="body2">
                                7-day moving average
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="h4"
                            sx={{ fontWeight: 500 }}
                            component="div"
                          >
                            {formatVitalValue(baseline)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                sx={{ minHeight: '100%' }}
                alignContent="center"
              >
                <Grid item>{formatUpdateChip()}</Grid>
                <Grid item sx={{ minHeight: '100%', alignSelf: 'center' }}>
                  {formatTrendArrow()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
