import EditDialog from '@/modules/ra-ui/components/edit-dialog';
import { patientStatus } from '../constants/patientStatus';
import React from 'react';
import {
  SelectInput,
  required,
  useCreate,
  useGetIdentity,
  useRecordContext,
} from 'react-admin';
import inflection from 'inflection';
import { DateTime } from 'luxon';

export function PatientStatusDialog({ open, setOpen }) {
  const record = useRecordContext();
  const [create] = useCreate();
  const { identity } = useGetIdentity();

  const transform = (data) => {
    return { patient_status: data.status };
  };

  if (!record) return null;

  const logMonitoringNote = (data) => {
    const patient_id = record?.id;

    create(`monitoring-activities`, {
      data: {
        patient_id: patient_id,
        practitioner_id: identity?.id,
        reviewed_on: DateTime.utc().toISO({ includeOffset: false }),
        type: 'activity',
        description:
          'Patient status updated to ' +
          inflection.humanize(data.patient_status, true),
      },
    });
  };
  return (
    <EditDialog
      title="Update patient status"
      resource={`patients`}
      transform={transform}
      notifyMessage="Patient status updated"
      open={open}
      setOpen={setOpen}
      refreshOnSuccess={true}
      onSuccess={(data) => {
        logMonitoringNote(data);
      }}
    >
      <div className="flex flex-col gap1">
        <SelectInput
          source="status"
          choices={patientStatus.filter((status) => !status.disabled)}
          optionText="name"
          helperText={false}
          validate={required()}
        />
      </div>
    </EditDialog>
  );
}
