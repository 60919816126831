import { Divider, Stack } from '@mui/material';
import inflection from 'inflection';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  required,
  useGetIdentity,
  usePermissions,
} from 'react-admin';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import { addDashToFourChars } from './DeviceExternalIdField';
import { measurementDeviceTypes } from '../constants/measurementDeviceTypes';
import { deviceManufacturers } from '../constants/deviceManufacturers';
import { parseGatewayId } from '../utils/parseGatewayId';
import { filterTenantName } from '@/utils/filterTenantName';
import { useFormContext, useWatch } from 'react-hook-form';
import { filterPatientName } from '@/utils/filterPatientName';

const tenoviGatewayIdValidator = (value) => {
  if (!value) {
    return 'Gateway ID is required';
  }
  const formattedStr = value.replace(/-/g, '');
  // Check if the string matches either of the two formats
  const regex = /^([a-zA-Z0-9]{4}-){2}[a-zA-Z0-9]{4}$|^[a-zA-Z0-9]{12}$/;
  if (regex.test(formattedStr)) {
    return undefined;
  } else {
    return 'Gateway ID must be 12 characters or 4 characters separated by dashes';
  }
};

const DeviceLocationInput = () => {
  const tenantId = useWatch({ name: 'tenant_id' });
  const patientId = useWatch({ name: 'patient_id' });
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue('location_id', null);
  }, [tenantId, patientId, setValue]);

  return (
    <ReferenceInput
      source="location_id"
      reference="locations"
      filter={{
        'is_active[eq]': true,
        'tenant_id[eq]': tenantId ? tenantId : null,
      }}
    >
      <AutocompleteInput
        label="Location"
        optionText="name"
        parse={(v) => (v === '' ? null : v)}
        helperText={
          !!patientId
            ? "Can't set location on devices assigned to a patient"
            : false
        }
        disabled={!!patientId}
      />
    </ReferenceInput>
  );
};

export const DeviceCreateButton = (props) => {
  const { variant = 'text' } = props;

  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();

  const transform = (data) => {
    let dataOut = {
      ...data,
      external_id: parseGatewayId(
        data.external_id,
        data.device_manufacturer,
        'tenovi_gateway',
      ),
      status: !data.device_received ? 'pending' : null,
    };
    if (get(permissions, 'is_internal') === false) {
      dataOut['tenant_id'] = identity && identity.tenant_id;
    }
    return dataOut;
  };

  return (
    <CreateInDialogButton
      title="Add device"
      transform={transform}
      redirect={null}
      fullWidth
      variant={variant}
      label="Device"
      saveAndAddAnother
      saveAndAddAnotherClearFields={['external_id']}
    >
      <Stack>
        <Stack gap={1} direction="row">
          <SelectInput
            source="device_manufacturer"
            choices={deviceManufacturers}
            fullWidth
            helperText="Select the manufacturer of the device"
            validate={required()}
          />
        </Stack>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.device_manufacturer === 'tenovi') {
              return (
                <>
                  <SelectArrayInput
                    source="device_types"
                    choices={measurementDeviceTypes}
                    optionText={(record) => inflection.humanize(record.id)}
                    helperText="Select the type of device. Keep empty to just add a gateway."
                  />
                  <RadioButtonGroupInput
                    source="gateway_add_type"
                    defaultValue="new"
                    choices={[
                      { id: 'new', name: 'New Gateway' },
                      { id: 'existing', name: 'Existing Gateway' },
                    ]}
                  />
                  {formData.gateway_add_type === 'existing' ? (
                    <ReferenceInput
                      source="external_id_device_id"
                      reference="devices"
                      filter={{
                        'device_type[eq]': 'tenovi_gateway',
                      }}
                      sort={{ field: 'added_on', order: 'DESC' }}
                    >
                      <AutocompleteInput
                        optionText={(record) =>
                          addDashToFourChars(record?.external_id)
                        }
                        label="Existing Gateway ID"
                        helperText="Select which gateway this device belongs to. Please be sure to add the gateway first."
                        validate={required()}
                        filterToQuery={(searchText) => ({
                          'external_id:like': `%${searchText}%`,
                        })}
                      />
                    </ReferenceInput>
                  ) : (
                    <TextInput
                      label="New Gateway ID"
                      source="external_id"
                      parse={(v) => (v === '' ? null : v)}
                      validate={tenoviGatewayIdValidator}
                      helperText={
                        formData.device_manufacturer === 'tenovi'
                          ? 'Dashes are not required for Tenovi Gateways'
                          : null
                      }
                    />
                  )}
                </>
              );
            } else {
              return (
                <>
                  <SelectInput
                    source="device_type"
                    choices={measurementDeviceTypes}
                    optionText={(record) => inflection.humanize(record.id)}
                    validate={required()}
                    helperText={false}
                  />
                  <TextInput
                    label="External ID"
                    source="external_id"
                    parse={(v) => (v === '' ? null : v)}
                    helperText={false}
                  />
                </>
              );
            }
          }}
        </FormDataConsumer>
        <TextInput
          source="device_model"
          helperText={false}
          label="Model number"
        />

        <Divider />
        {get(permissions, 'is_internal') === true ? (
          <ReferenceInput source="tenant_id" reference="providers">
            <AutocompleteInput
              label="Provider"
              optionText="name"
              helperText="Select which provider this device belongs to"
              filterToQuery={filterTenantName}
            />
          </ReferenceInput>
        ) : null}
        <DeviceLocationInput />
        <BooleanInput
          source="device_received"
          defaultValue={false}
          helperText="Has the device been received at the location?"
        />
        <BooleanInput
          source="is_shared_device"
          defaultValue={false}
          helperText="Do you intend to use this device for multiple patients? (ie. nursing facility)"
        />

        <FormDataConsumer>
          {({ formData }) => {
            if (!formData.is_shared_device) {
              return (
                <ReferenceInput
                  source="patient_id"
                  reference="patients"
                  sort={{ field: 'full_name', order: 'ASC' }}
                  filter={{
                    'tenant_id[eq]': formData.tenant_id || identity?.tenant_id,
                    'patient_status[in]': ['active', 'enrolled'],
                  }}
                >
                  <AutocompleteInput
                    filterToQuery={filterPatientName}
                    label="Patient"
                    optionText="user.full_name"
                    parse={(v) => (v === '' ? null : v)}
                    disabled={!formData.tenant_id || !formData.external_id}
                    helperText={
                      !formData.tenant_id || !formData.external_id
                        ? 'Please pick a provider and provide an external ID.'
                        : 'Select which patient this device is assigned to'
                    }
                  />
                </ReferenceInput>
              );
            } else {
              return null;
            }
          }}
        </FormDataConsumer>
      </Stack>
    </CreateInDialogButton>
  );
};
