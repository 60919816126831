import { ValidationError } from 'ra-core';
import React from 'react';

export const InputMessage = (props) => {
  const { touched, error } = props;

  if (touched && error) {
    return (
      <p className={'text-sm font-medium text-destructive'}>
        <ValidationError error={error} />
      </p>
    );
  }

  return null;
};
