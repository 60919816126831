import { SimpleTable } from '@/modules/ra-ui/components/simple-table';
import { Input } from '@/modules/ui/components/input';
import { Skeleton } from '@/modules/ui/components/skeleton';
import { ColumnDef } from '@tanstack/react-table';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import {
  useGetList,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import * as z from 'zod';

const EditableCell = ({ getValue, row: { index }, column: { id }, table }) => {
  const { register, getFieldState } = useFormContext();

  return (
    <>
      <Input
        {...register('negotiated_price')}
        type="number"
        className="w-fit"
      />
      {getFieldState('negotiated_price').error && (
        <span className="text-red-500 text-sm">
          {getFieldState('negotiated_price').error.message as string}
        </span>
      )}
    </>
  );
};

export const FeeForServiceList = () => {
  const record = useRecordContext();

  const [update] = useUpdate(`providers/${record?.id}/fee-for-service-pricing`);
  const refresh = useRefresh();
  const notify = useNotify();

  const { data, isFetching } = useGetList(
    `providers/${record?.id}/fee-for-service-pricing`,
    {
      pagination: { page: 1, perPage: 30 },
      filter: {},
      sort: { field: 'id', order: 'ASC' },
    },
  );

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'id',
      },
      {
        accessorKey: 'cpt_code',
        header: () => <span>CPT Code</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'fee_schedule_price',
        header: () => <span>Fee Schedule Price</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'negotiated_price',
        header: () => <span>Negotiated Price</span>,
        cell: EditableCell,
      },
      {
        accessorKey: 'as_of_date',
        header: () => <span>As Of Date</span>,
        cell: (info) =>
          DateTime.fromISO(info.getValue() as string).toLocaleString(
            DateTime.DATE_SHORT,
          ),
      },
    ],
    [],
  );

  if (!record) {
    return null;
  }

  const onSaveRow = (data, initialData) => {
    update(
      `providers/${record?.id}/fee-for-service-pricing`,
      {
        id: initialData.id,
        data: {
          negotiated_price: data.negotiated_price,
        },
      },
      {
        onSuccess: () => {
          refresh();
        },
        onError: (error) => {
          refresh();
          notify(`Unable to save changes: ${get(error, 'message')}`, {
            type: 'warning',
          });
        },
      },
    );
  };

  if (isFetching) {
    return <Skeleton className="w-full h-96" />;
  }

  return (
    <SimpleTable
      data={data}
      columns={columns}
      isEditable
      onSaveRow={onSaveRow}
      schema={z.object({
        negotiated_price: z.coerce.number().min(0).multipleOf(0.01),
      })}
      tableProps={{
        initialState: {
          columnVisibility: {
            id: false,
          },
        },
      }}
    />
  );
};
