import { Button } from '@/modules/ui/components/button';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { get } from 'lodash';
import { Building2 } from 'lucide-react';
import React, { useState } from 'react';
import {
  AutocompleteInput,
  Form,
  ReferenceInput,
  SaveButton,
  required,
  usePermissions,
  useRefresh,
  useRecordContext,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { useMutation } from 'react-query';

export const AssignToProviderButton = (props) => {
  const { resource, label = 'Set Provider', allowNullOption = false } = props;

  const record = useRecordContext();
  const { permissions } = usePermissions();

  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { mutate, isLoading } = useMutation((value) =>
    dataProvider.updatePatch(resource, {
      id: `${record?.id}/set_owner`,
      data: value,
    }),
  );

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (value) => {
    mutate(value, {
      onSuccess: () => {
        refresh();
        setOpen(false);
        notify('Successfully updated the provider', {
          type: 'info',
        });
      },
      onError: (error) => {
        notify(
          get(error, 'message') ||
            'An error occurred while updating the record',
          {
            type: 'error',
          },
        );
      },
    });
  };

  if (get(permissions, 'is_internal') !== true) {
    return null;
  }

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        disabled={isLoading}
        variant="outline"
        size="sm"
        className="text-black"
        type="button"
      >
        <Building2 className="w-4 h-4 mr-2" />
        {label}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <Form onSubmit={onSubmit}>
          <DialogTitle>
            <div>Select a provider</div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ReferenceInput source="tenant_id" reference="providers">
              <AutocompleteInput
                label="Provider"
                optionText="name"
                validate={!!allowNullOption ? required() : null}
              />
            </ReferenceInput>
          </DialogContent>
          <DialogActions>
            <SaveButton />
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};
