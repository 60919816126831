export const patientStatus = [
  {
    id: 'active',
    name: 'Active',
    className: 'border-green-300 bg-green-100',
  },

  {
    id: 'pending_enrollment',
    name: 'Pending',
    className: 'border-blue-300 bg-blue-100',
  },

  {
    id: 'declined',
    name: 'Declined',
    className: 'border-grey-300 bg-grey-100',
  },
  {
    id: 'disabled',
    name: 'Unenrolled',
    className: 'border-rose-300 bg-rose-100',
    disabled: true,
  },
];
