import React from 'react';
import { MedicationsCreateButton } from '../../../medications/components/MedicationsCreateButton';
import { MedicationsList } from '../../../medications/components/MedicationsList';

export const MedicationsSection = () => {
  return (
    <div className="flex flex-col space-y-2 mt-4">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-xl font-semibold">Medications</h3>
          <p className="text-sm text-gray-500 max-w-[800px]">
            Medications that have been prescribed to the patient
          </p>
        </div>

        <MedicationsCreateButton />
      </div>
      <MedicationsList />
    </div>
  );
};
