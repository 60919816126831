import React from 'react';
import { Datagrid, FunctionField, useGetList } from 'react-admin';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import BadgeField from '@/modules/ra-ui/components/badge-field';

export const AuthLogPanel = ({ userId }) => {
  const { data, isLoading } = useGetList('auth-logs', {
    pagination: { page: 1, perPage: 15 },
    sort: { field: 'timestamp', order: 'DESC' },
    filter: {
      'user_id[eq]': userId,
      'event_type[ne]': 'refresh_token_expired',
    },
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Security Log</CardTitle>
        <CardDescription>
          A log of recent security events. Please contact support if you see
          anything suspicious.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Datagrid
          data={data}
          isLoading={isLoading}
          bulkActionButtons={false}
          sort={{ field: 'timestamp', order: 'DESC' }}
        >
          <LuxonDateField source="timestamp" showTime />
          <BadgeField source="event_type" />
          <FunctionField
            source="ip_address"
            label="IP Address"
            render={(record) => (
              <a
                href={`https://whatismyipaddress.com/ip/${record.ip_address}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500"
              >
                {record.ip_address}
              </a>
            )}
          />
        </Datagrid>
      </CardContent>
    </Card>
  );
};
