// This component should let users set tenant and location of devices.
import { Button } from '@/modules/ui/components/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';
import { Input } from '@/modules/ui/components/input';
import { TextInput } from '@/modules/ui/inputs/text-input';
import { cn } from '@/modules/ui/utils/cn';

import React, { useState } from 'react';
import {
  Form,
  required,
  useCreate,
  useGetIdentity,
  useGetOne,
  useNotify,
  useRefresh,
} from 'react-admin';

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';

export const TwoFactorSetup = () => {
  const refresh = useRefresh();

  const [open, setOpen] = useState(false);
  const { identity } = useGetIdentity();

  const { data } = useGetOne(
    'users',
    { id: identity?.id },
    {
      enabled: !!identity?.id,
    },
  );
  const [generate, { data: dataGen, reset }] = useCreate();
  const [verify] = useCreate();
  const [disable] = useCreate();
  const notify = useNotify();
  const { refetch } = useGetIdentity();

  const generateSecret = () => {
    generate('auth/generate-2fa', {});
  };

  const clickVerify2FA = (formData: any) => {
    verify(
      'auth/enable-2fa',
      {
        data: {
          code: formData.code,
        },
      },
      {
        onSuccess: () => {
          refresh();
          setOpen(false);
          notify('2FA is now enabled', { type: 'success' });
          reset();
          localStorage.removeItem('RaStore.user');
          refetch();
        },
      },
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clickDisable2FA = (formData: any) => {
    disable(
      'auth/disable-2fa',
      {
        data: {
          password: formData.password,
        },
      },
      {
        onSuccess: (data) => {
          refresh();
          setOpen(false);
          notify('2FA is now disabled', { type: 'success' });
          reset();
          localStorage.removeItem('RaStore.user');
          refetch();
        },
      },
    );
  };

  return (
    <>
      <Card className="max-w-lg w-full">
        <CardHeader>
          <CardTitle className="text-2xl">2-Factor Authentication</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col text-sm  gap-2">
            <div className="flex items-center gap-2 justify-between">
              <div>
                <strong>2-Factor Authentication:</strong>{' '}
                {data?.totp_enabled ? 'Enabled' : 'Disabled'}
              </div>
              <Button
                onClick={handleClickOpen}
                disabled={open}
                variant="outline"
                size="sm"
                className="text-black"
                type="button"
              >
                {data?.totp_enabled ? 'Configure' : 'Setup'}
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      <Dialog
        open={open}
        // onClose={handleClose}
      >
        <DialogContent
          onClose={handleClose}
          className={cn('min-w-[400px] overflow-y-auto max-h-[90vh]')}
        >
          <DialogHeader>
            <DialogTitle>Setup Two Factor Authentication</DialogTitle>
            <DialogDescription className="max-w-[400px]">
              Vironix supports TOTP as a 2FA method. You will need to make use
              of apps like Google Authenticator, Authy, or other 2FA Apps.
            </DialogDescription>
          </DialogHeader>
          {data?.totp_enabled && (
            <div className="flex flex-col gap-2">
              <p>You have already 2FA enabled</p>
              <p className="text-sm text-red-500">
                We highly recommend enabling 2FA for security reasons.
              </p>
              <Form onSubmit={clickDisable2FA}>
                <div className="flex flex-col gap-2">
                  <TextInput
                    source="password"
                    validate={[required()]}
                    label="Current password"
                    type="password"
                    autoComplete="current-password"
                  />

                  <Button type="submit" variant="destructive">
                    Disable 2FA
                  </Button>
                </div>
              </Form>
            </div>
          )}

          {!data?.totp_enabled && !!!dataGen && (
            <Button variant="outline" onClick={generateSecret}>
              Setup 2FA
            </Button>
          )}
          {dataGen && (
            <div className="flex flex-col text-sm  gap-2">
              <div className="flex flex-col gap-1">
                <strong>Secret Key:</strong>
                <Input value={dataGen.secret_key} contentEditable={false} />
              </div>
              <p>Or </p>
              <div>
                <strong>QR Code:</strong>
                <p>
                  Scan the QR Code with your 2FA App and enter the code below to
                  verify.
                </p>
                <img
                  src={`data:image/png;base64,${dataGen.qr_code}`}
                  alt="QR Code"
                  className="w-48 h-48 mx-auto"
                />
              </div>
              <Form onSubmit={clickVerify2FA}>
                <div className="flex flex-row gap-2 items-end justify-center">
                  <TextInput source="code" validate={[required()]} />
                  <Button>Verify</Button>
                </div>
              </Form>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
