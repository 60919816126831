import RangeField from '@/modules/ra-ui/components/RangeField';
import { DataTableFilter } from '@/modules/data-table/components/data-table-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import TabbedWrapper from '@/modules/ra-ui/components/tabbed-wrapper';
import { cropText } from '@/modules/patients/utils/cropText';
import React from 'react';
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  Pagination,
  TextField,
  useGetList,
  useRecordContext,
} from 'react-admin';
import { encounterStatus } from '../../constants/encounterStatus';
import { encounterType } from '../../constants/encounterType';
import { encounterClass } from '../../constants/encounterClass';
import { EncounterCreateButton } from '../../encounters/components/EncounterCreateButton';
import { EncounterEditButton } from '../../encounters/components/EncounterEditButton';

function ExpandedRow() {
  const record = useRecordContext();

  return (
    <div className="py-2">
      <p className="text-sm font-semibold text-gray-500 mb-1">Notes</p>
      <p className="whitespace-pre-wrap">{record.notes}</p>
    </div>
  );
}

export function EncountersDataGrid(props) {
  const { record } = props;

  const [sort, setSort] = React.useState({ field: null, order: null });
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [selectedTab, setSelectedTab] = React.useState('all');
  const [filterValues, setFilters] = React.useState({});

  const { data, total, isLoading } = useGetList(
    `patients/${record.id}/encounters`,
    {
      pagination: { page, perPage },
      filter: {
        ...(selectedTab !== 'all' && { 'source[eq]': selectedTab }),
        ...filterValues,
      },
      sort,
    },
  );
  if (!record) {
    return null;
  }

  const handleTabChange = (value) => {
    setSelectedTab(value);
    setPage(1);
  };

  return (
    <div>
      <ListContextProvider
        // @ts-ignore
        value={{ filterValues, setFilters, displayedFilters: {} }}
      >
        <TabbedWrapper
          options={[
            { value: 'all', href: 'all', title: 'All' },
            { value: 'vironix', href: 'vironix', title: 'Vironix' },
            { value: 'HIE-TH', href: 'hie', title: 'HIE' },
          ]}
          selectedTab={selectedTab}
          setSelectedTab={handleTabChange}
        >
          <DataTableToolbar
            showViewOptions={false}
            search={false}
            className="mx-2"
          >
            <DataTableFilter
              column="status[in]"
              title="Status"
              options={encounterStatus.map((choice) => ({
                label: choice.name,
                value: choice.id,
              }))}
            />
            <DataTableFilter
              column="encounter_type[in]"
              title="Encounter Type"
              options={encounterType.map((choice) => ({
                label: choice.name,
                value: choice.id,
              }))}
            />
          </DataTableToolbar>
          <Datagrid
            data={data}
            total={total}
            isLoading={isLoading}
            sort={sort}
            bulkActionButtons={false}
            setSort={setSort}
            empty={
              <EmptyDatagrid
                resourceName="Encounters"
                textFormatString="No encounters found for patient"
              />
            }
            expand={<ExpandedRow />}
            rowClick="expand"
            expandSingle
          >
            <RangeField
              source_start="start_date"
              source_end="end_date"
              sortBy="start_date"
              label="Timestamp"
            />
            <TextField source="department" />
            <BadgeField source="status" />
            <BadgeField
              source="encounter_type"
              mapping={encounterType}
              humanize={false}
            />
            <BadgeField source="encounter_class" mapping={encounterClass} />
            <BadgeField source="source" />
            <FunctionField
              render={(record) => cropText(record.notes, 120)}
              label="Notes"
            />
            <EncounterEditButton />
          </Datagrid>
          {data && data.length ? (
            <Pagination
              total={total}
              perPage={perPage}
              page={page}
              setPage={setPage}
              setPerPage={setPerPage}
            />
          ) : null}
        </TabbedWrapper>
      </ListContextProvider>
    </div>
  );
}

export default function EncountersSection() {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-2 mt-4">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-xl font-semibold">Encounters</h3>
          <p className="text-sm text-gray-500 max-w-[800px]">
            Interactions between a patient and healthcare provider(s) for the
            purpose of providing healthcare service(s) or assessing the health
            status of a patient.
          </p>
        </div>

        <EncounterCreateButton />
      </div>
      <EncountersDataGrid record={record} />
    </div>
  );
}
