import { Button } from '@/modules/ui/components/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';
import { FileDown, Loader2 } from 'lucide-react';
import { DateTime } from 'luxon';
import React from 'react';
import {
  AutocompleteInput,
  Form,
  ReferenceInput,
  SelectInput,
  required,
  useDataProvider,
  useListContext,
  useNotify,
  usePermissions,
} from 'react-admin';
import { useFormState } from 'react-hook-form';
import { get } from 'lodash';
import { filterTenantName } from '@/utils/filterTenantName';

// MUI dialog for export
export const ExportClaimItemsButton = () => {
  const { permissions } = usePermissions();
  const [open, setOpen] = React.useState(false);
  const { filterValues } = useListContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const notify = useNotify();

  const dataprovider = useDataProvider();

  const handleClickOpen = () => {
    setIsLoading(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onExport = (values) => {
    setIsLoading(true);

    const startDate = get(filterValues, 'suggested_billing_date[gte]');
    const endDate = get(filterValues, 'suggested_billing_date[lte]');
    const createdOnGte = get(filterValues, 'created_on[gte]');
    const createdOnLte = get(filterValues, 'created_on[lte]');
    const cptCode = get(filterValues, 'cpt_code[in]');

    dataprovider
      .getFile(`claim-items/export`, {
        meta: {
          start_date: startDate
            ? DateTime.fromISO(startDate).toISODate()
            : null,
          end_date: endDate ? DateTime.fromISO(endDate).toISODate() : null,
          patient_id: get(filterValues, 'patient_id[eq]'),
          submitted: get(filterValues, 'submitted[eq]'),
          export_type: values.export_type,
          tenant_id: values.tenant_id ? values.tenant_id : null,
          cpt_code: cptCode || undefined,
          created_on_gte: createdOnGte
            ? DateTime.fromISO(createdOnGte).toISODate()
            : undefined,
          created_on_lte: createdOnLte
            ? DateTime.fromISO(createdOnLte).toISODate()
            : undefined,
        },
        download: values.export_type === 'csv' ? 'export.csv' : 'export.pdf',
        downloadType:
          values.export_type === 'csv' ? 'text/csv' : 'application/pdf',
      })
      .then(() => {
        return new Promise((resolve) => setTimeout(resolve, 500));
      })
      .then(() => {
        setIsLoading(false);
        setOpen(false);
      })
      .catch(() => {
        notify('Error exporting claims', { type: 'error' });
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        size="sm"
        className="h-8"
        variant="outline"
      >
        <FileDown className="mr-2 h-4 w-4" />
        Export
      </Button>

      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogContent onClose={handleClose}>
          <DialogHeader>
            <DialogTitle>Export Claims</DialogTitle>
            <DialogDescription>
              Export claims matching the filters set in the list view.
            </DialogDescription>
          </DialogHeader>
          <Form onSubmit={onExport}>
            <div className="grid grid-col">
              {get(permissions, 'is_internal') === true ? (
                <ReferenceInput
                  source="tenant_id"
                  reference="providers"
                  sort={{
                    field: 'name',
                    order: 'ASC',
                  }}
                >
                  <AutocompleteInput
                    filterToQuery={filterTenantName}
                    label="Provider"
                    optionText="name"
                    helperText={false}
                    validate={required()}
                  />
                </ReferenceInput>
              ) : null}
              <SelectInput
                source="export_type"
                label="Export Type"
                choices={[
                  { name: 'CSV', id: 'csv' },
                  { name: 'PDF', id: 'pdf' },
                ]}
                validate={required()}
                helperText={false}
                defaultValue={'csv'}
              />
            </div>
            <DialogFooter className="items-center pt-4">
              <p className="text-xs text-justify p-2">
                Nothing in these exports should be construed as a guarantee that
                specific services will be reimbursed. Reimbursement rates vary
                by geography and are based on the location of the billing
                practitioner when delivering the services. Billing practitioners
                should check with their local Medicare Administrative Contractor
                (“MAC”) and/or specific commercial payers for confirmation of
                reimbursement rates for these services.
              </p>
              <SubmitButton isLoading={isLoading} />
            </DialogFooter>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const SubmitButton = ({ isLoading }) => {
  const { isValid, isValidating, isSubmitting } = useFormState();

  const disabled = isValidating || isSubmitting || !isValid || isLoading;
  return (
    <Button disabled={disabled} type="submit" className="mt-2">
      {isLoading ? (
        <>
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Please wait
        </>
      ) : (
        <>
          <FileDown className="mr-2 h-4 w-4 " />
          Export
        </>
      )}
    </Button>
  );
};
