import { CheckIcon, PlusCircle } from 'lucide-react';
import * as React from 'react';

import { Badge } from '@/modules/ui/components/badge';
import { Button } from '@/modules/ui/components/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/modules/ui/components/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/modules/ui/components/popover';
import { Separator } from '@/modules/ui/components/separator';
import { cn } from '@/modules/ui/utils/cn';
import { get, isEqual } from 'lodash';
import { useListContext } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';

interface CareProgramDataTableFilterProps {
  title?: string;
  options?: {
    label: string;
    filterKey: string;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
}

const DEFAULT_OPTIONS = [
  { label: 'RPM', filterKey: 'has_rpm[eq]' },
  { label: 'CCM', filterKey: 'has_ccm[eq]' },
  { label: 'PCM', filterKey: 'has_pcm[eq]' },
  { label: 'RTM', filterKey: 'has_rtm[eq]' },
];

export function CareProgramDataTableFilter({
  title = 'Care Programs',
  options = DEFAULT_OPTIONS,
}: CareProgramDataTableFilterProps) {
  const { filterValues, setFilters } = useListContext();
  const form = useForm<Record<string, boolean | undefined>>({
    defaultValues: options.reduce(
      (acc, option) => {
        acc[option.filterKey] = get(filterValues, option.filterKey) as
          | boolean
          | undefined;
        return acc;
      },
      {} as Record<string, boolean | undefined>,
    ),
  });

  React.useEffect(() => {
    options.forEach((option) => {
      const newValue = get(filterValues, option.filterKey, null) as
        | boolean
        | null;
      const previousValue = form.getValues(option.filterKey);
      if (!isEqual(newValue, previousValue)) {
        form.setValue(option.filterKey, newValue ?? undefined);
      }
    });
  }, [filterValues, form, options]);

  React.useEffect(() => {
    const subscription = form.watch((values) => {
      const newFilters = { ...filterValues };

      console.log('values', values);

      options.forEach((option) => {
        if (values[option.filterKey]) {
          newFilters[option.filterKey] = values[option.filterKey];
        } else {
          delete newFilters[option.filterKey];
        }
      });
      setFilters(newFilters, null);
    });
    return () => subscription.unsubscribe();
  }, [form, setFilters, filterValues, options]);

  return (
    <FormProvider {...form}>
      <CareProgramDataTableFilterInput title={title} options={options} />
    </FormProvider>
  );
}
function CareProgramDataTableFilterInput({
  title,
  options,
}: CareProgramDataTableFilterProps) {
  const { filterValues, setFilters } = useListContext();

  const selectedValues = new Set(
    options
      .filter((option) => get(filterValues, option.filterKey) === true)
      .map((option) => option.filterKey),
  );

  const onChange = (newSelectedValues: Set<string>) => {
    const newFilters = { ...filterValues };
    options.forEach((option) => {
      if (newSelectedValues.has(option.filterKey)) {
        newFilters[option.filterKey] = true;
      } else {
        delete newFilters[option.filterKey];
      }
    });
    setFilters(newFilters, null);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 border-dashed">
          <PlusCircle className="mr-2 h-4 w-4" />
          {title}
          {selectedValues.size > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge
                variant="secondary"
                className="rounded-sm px-1 font-normal lg:hidden"
              >
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selectedValues.size > 2 ? (
                  <Badge
                    variant="secondary"
                    className="rounded-sm px-1 font-normal"
                  >
                    {selectedValues.size} selected
                  </Badge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(option.filterKey))
                    .map((option) => (
                      <Badge
                        variant="secondary"
                        key={option.filterKey}
                        className="rounded-sm px-1 font-normal"
                      >
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.filterKey);
                return (
                  <CommandItem
                    key={option.filterKey}
                    onSelect={() => {
                      const newSelectedValues = new Set(selectedValues);
                      if (isSelected) {
                        newSelectedValues.delete(option.filterKey);
                      } else {
                        newSelectedValues.add(option.filterKey);
                      }
                      onChange(newSelectedValues);
                    }}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        isSelected
                          ? 'bg-primary text-primary-foreground'
                          : 'opacity-50 [&_svg]:invisible',
                      )}
                    >
                      <CheckIcon className={cn('h-4 w-4')} />
                    </div>
                    {option.icon && (
                      <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                    )}
                    <span>{option.label}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => onChange(new Set())}
                    className="justify-center text-center"
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
