import React from 'react';

import { useRecordContext } from 'react-admin';

import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import {
  BooleanInput,
  DateInput,
  SelectInput,
  TextInput,
  required,
} from 'react-admin';
import { MedicationDosageInput } from './MedicationDosageInput';
import { MedicationSearchInput } from './MedicationSearchInput';

const frequencies = [
  {
    id: 'daily',
    name: 'Daily',
  },
  {
    id: 'weekly',
    name: 'Weekly',
  },
  {
    id: 'monthly',
    name: 'Monthly',
  },
];

export const MedicationEditButton = ({
  variant = 'default',
  label = 'Edit Medication',
}) => {
  const record = useRecordContext();

  const transform = (data) => {
    return {
      ...data,
      update_reason: 'incorrect-details',
    };
  };

  return (
    <EditInDialogButton
      title="Edit medication"
      resource={`patients/${record?.patient_id}/medication-administrations`}
      transform={transform}
      variant={variant}
      label={label}
      notifyMessage="Medication updated"
      refreshOnSuccess={true}
    >
      <div className="flex flex-col gap-1">
        <MedicationSearchInput />
        <div className="flex flex-col">
          <p>Dosing</p>
          <MedicationDosageInput />
          <div className="flex flex-row gap-1 items-center">
            <TextInput
              source="dosage.rate.numerator"
              label="Amount"
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              validate={required()}
            />
            <SelectInput
              source="dosage.rate.denominator"
              choices={frequencies}
              label="Frequency"
              validate={required()}
            />
          </div>
          <BooleanInput source="dosage.as_needed" label="As Needed" />
        </div>
        <div className="flex flex-col">
          <p>Dosage Period</p>
          <div className="flex flex-row gap-1">
            <DateInput
              source="start_date"
              label="Start Date"
              validate={required()}
            />
            <DateInput source="end_date" label="End Date" />
          </div>
        </div>
        <TextInput
          source="additional_info"
          label="Notes"
          multiline
          fullWidth
          minRows={3}
        />
      </div>
    </EditInDialogButton>
  );
};
