import ProviderField from '@/modules/ra-ui/components/ProviderField';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import { Card, CardContent } from '@/modules/ui/components/card';
import { formatDuration } from '@/utils/formatDuration';
import { get } from 'lodash';

import React from 'react';
import {
  ArrayField,
  CLOSED_DRAWER_WIDTH,
  DRAWER_WIDTH,
  Datagrid,
  DateField,
  ReferenceField,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  useSidebarState,
} from 'react-admin';
import ReportHeaderTemplate from './ReportHeaderTemplate';
const BUFFER = 28;

const CarePlanReportContent = (props) => {
  const record = useRecordContext();
  const [open] = useSidebarState();
  const sidebarWidth = open
    ? DRAWER_WIDTH + BUFFER
    : CLOSED_DRAWER_WIDTH + BUFFER;

  if (!record) {
    return null;
  }

  if (!record.content || !record.content.length) {
    return (
      <Card
        className={`w-full overflow-auto`}
        style={{
          maxWidth: `calc(100vw - ${sidebarWidth}px)`,
        }}
      >
        <CardContent className="p-2">
          <p>No patients found.</p>
        </CardContent>
      </Card>
    );
  }

  const headers = Object.keys(record.content[0]);

  return (
    <Card
      className={`w-full overflow-auto`}
      style={{
        maxWidth: `calc(100vw - ${sidebarWidth}px)`,
      }}
    >
      <CardContent className="p-2">
        <ArrayField source={`content`}>
          <Datagrid bulkActionButtons={false}>
            <ReferenceField
              label="Patient"
              source="patient_id"
              reference="users"
              link={(item) => `/patient-queue/${item.id}/show#services`}
              sortable={false}
              emptyText="N/A"
            >
              <TextField source="full_name" />
            </ReferenceField>
            {headers
              .filter((h) => h !== 'patient_id')
              .map((header) => (
                <TableField key={header} source={header} />
              ))}
          </Datagrid>
        </ArrayField>
      </CardContent>
    </Card>
  );
};

const TableField = (props) => {
  const { source, emptyText = null } = props;
  const record = useRecordContext();
  let classes = '';

  let value = get(record, source);

  if (value === null || value === '') {
    return emptyText ? <span>{emptyText}</span> : null;
  }

  if (source.endsWith('remaining_time') || source.endsWith('remaining_count')) {
    // if the value is more than 0, underline in yellow; keep text black
    if (value > 0) {
      classes = 'border-b-4 border-yellow-500 pr-2';
    }
  }

  if (source.endsWith('_time')) {
    value = formatDuration(value);
  }

  return <span className={classes}>{value}</span>;
};

const CarePlanReportHeader = () => {
  return (
    <ReportHeaderTemplate>
      <>
        <SimpleShowLayout>
          <DateField source="report_date" />
          <TextField source="name" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <BadgeField source="type" />

          <ProviderField label="Provider" />
        </SimpleShowLayout>
      </>
    </ReportHeaderTemplate>
  );
};

export { CarePlanReportContent, CarePlanReportHeader };
