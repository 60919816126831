import { monitoringActivityTypes } from '@/modules/monitoring-activity/constants/monitoringActivityTypes';
import { serviceInstanceTypes } from '@/modules/patients/constants/serviceInstanceTypes';
import DateTimeProviderTZInput from '@/modules/ra-ui/components/DateTimeProviderTZInput';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { get, isEmpty, find, filter, map } from 'lodash';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import {
  BooleanInput,
  FormDataConsumer,
  SelectInput,
  required,
  useGetIdentity,
  useInput,
  useRecordContext,
} from 'react-admin';
import { useController, useFormContext } from 'react-hook-form';
import { CustomPallete } from '../../../utils/color';
import presetNotes from '../constants/presetNotes';
import IncompatibleBillingWarning from './IncompatibleBillingWarning';

export const ManualTimer = (props) => {
  const { variant = 'full' } = props;

  const { field, fieldState } = useController({
    name: 'duration',
    defaultValue: 0,
    rules: { min: 30 },
  });

  const reset = () => {
    field.onChange(0);
  };

  const getSeconds = () => {
    return Math.floor((field.value / 1) % 60);
  };
  const getMinutes = () => {
    return Math.floor((field.value / 60) % 60);
  };

  const onChangeMinute = (event) => {
    const value = Number(event.target.value);
    if (isNaN(value)) return;

    if (value < 0) {
      field.onChange(getSeconds());
    } else {
      field.onChange(value * 60 + getSeconds());
    }
  };

  const onChangeSecond = (event) => {
    const value = Number(event.target.value);
    if (isNaN(value)) return;

    if (value < 0) {
      field.onChange(getMinutes());
    } else {
      field.onChange(getMinutes() * 60 + value);
    }
  };

  return (
    <Grid container alignItems="end">
      <Grid item sx={{ marginLeft: 1, marginRight: '6px' }}>
        <div
          style={{
            fontSize: '34px',
            fontWeight: '300',
            color: CustomPallete.neutral10,
          }}
        >
          <Stack direction="row" spacing={1} alignItems="end">
            <span>
              <TextField
                variant="standard"
                value={(field.value === 0
                  ? ''
                  : '0' + Math.floor(field.value / 60)
                ).slice(-2)}
                style={{ width: 40 }}
                onChange={onChangeMinute}
                label="Min"
                error={fieldState.invalid}
              />
            </span>
            <span>:</span>
            <span>
              <TextField
                value={(field.value === 0
                  ? ''
                  : '0' + Math.floor((field.value / 1) % 60)
                ).slice(-2)}
                variant="standard"
                style={{ width: 40 }}
                onChange={onChangeSecond}
                label="Sec"
                error={fieldState.invalid}
              />
            </span>
          </Stack>
        </div>
      </Grid>
      {/* {variant === 'full' ? (
        <Grid item>
          <IconButton
            size="medium"
            color="primary"
            // onClick={() => setTime(5 * 60)}
            title="Preset times"
          >
            <AccessTimeIcon fontSize="medium" />
          </IconButton>
        </Grid>
      ) : null} */}
      {variant === 'full' ? (
        <Grid item>
          <IconButton
            size="medium"
            color="primary"
            onClick={reset}
            title="Clear timer"
          >
            <ClearIcon fontSize="medium" />
          </IconButton>
        </Grid>
      ) : null}
    </Grid>
  );
};

const monitoringActivityTypeChoices = map(monitoringActivityTypes, (v, k) => ({
  name: v,
  id: k,
}));

export const NotesField = (props) => {
  const { onChange, onBlur, validate, activityType, ...rest } = props;
  const { field, isRequired } = useInput({
    // Pass the event handlers to the hook but not the component as the field property already has them.
    // useInput will call the provided onChange and onBlur in addition to the default needed by react-hook-form.
    onChange,
    onBlur,
    validate,
    ...rest,
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { setValue } = useFormContext();

  const handleClickPresetMessage = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    const message = event.currentTarget.dataset.message;
    setValue('description', message);
    setAnchorEl(null);
  };

  return (
    <Stack marginBottom="20px">
      <>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {activityType
            ? get(presetNotes, activityType, []).map((message, key) => (
                <MenuItem
                  onClick={handleClickPresetMessage}
                  data-message={message.body}
                  key={key}
                >
                  {message.name}
                </MenuItem>
              ))
            : null}
        </Menu>
      </>
      <TextField
        fullWidth
        multiline
        minRows={2}
        maxRows={10}
        label="Notes"
        placeholder="Add any notes here"
        onChange={onChange}
        onBlur={onBlur}
        required={isRequired}
        {...field}
      />
      {activityType && get(presetNotes, activityType) ? (
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          color="primary"
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
          size="large"
          style={{
            alignSelf: 'flex-start',
            fontWeight: '500',
            color: CustomPallete.primary6,
          }}
        >
          Preset notes
        </Button>
      ) : null}
    </Stack>
  );
};

export const MonitoringActivityCreateButton = (props) => {
  const patientRecord = useRecordContext();
  const { identity } = useGetIdentity();
  const { tenant_id } = props;

  const serviceInstanceChoices = useMemo(
    () =>
      get(patientRecord, 'service_instances', []).map((i) => ({
        id: i.id,
        name: serviceInstanceTypes[i.type],
      })),
    [patientRecord],
  );

  if (!patientRecord) return null;

  const transform = (data) => ({
    ...data,
    patient_id: patientRecord.id,
    practitioner_id: identity.id,
    reviewed_on: data.reviewed_on
      ? data.reviewed_on
      : `${DateTime.utc().toISO()}`,
    type: data.type || 'note',
  });

  return (
    <CreateInDialogButton
      title="Add monitoring activity"
      transform={transform}
      redirect={null}
      fullWidth
      variant="default"
      label="Monitoring Activity"
      resource="monitoring-activities"
      initialData={{
        service_instance_id: serviceInstanceChoices[0]?.id,
      }}
      closeOnClickOutside={false}
    >
      <div className="flex flex-row gap-1 items-center ">
        <ManualTimer />
        <DateTimeProviderTZInput
          source="reviewed_on"
          label="Date"
          tenant_id={tenant_id}
          defaultValue={DateTime.utc().startOf('second').toISO({
            includeOffset: false,
            suppressMilliseconds: true,
          })}
        />
      </div>
      {!isEmpty(serviceInstanceChoices) ? (
        <SelectInput
          label="Care Program"
          source="service_instance_id"
          choices={serviceInstanceChoices}
          fullWidth
          helperText={false}
        />
      ) : null}
      <FormDataConsumer>
        {({ formData }) => {
          const matchedServiceInstance = find(
            get(patientRecord, 'service_instances', []),
            {
              id: formData.service_instance_id,
            },
          );

          if (!matchedServiceInstance) return null;
          const choices = filter(monitoringActivityTypeChoices, (v) =>
            v.id.startsWith(matchedServiceInstance.type),
          );

          choices.unshift({ name: 'Note', id: 'note' });

          return (
            <>
              <SelectInput
                source="type"
                choices={choices}
                fullWidth
                helperText="Specify which service code this work belongs to."
                validate={[required()]}
              />
              <IncompatibleBillingWarning patient_id={patientRecord?.id} />
            </>
          );
        }}
      </FormDataConsumer>
      <Stack direction="row">
        <BooleanInput
          source="data_reviewed"
          defaultValue={false}
          helperText={false}
          label={<Typography variant="body1">Data reviewed</Typography>}
        />
        <BooleanInput
          source="patient_contacted"
          defaultValue={false}
          helperText={false}
          label={<Typography variant="body1">Patient contacted</Typography>}
        />
      </Stack>
      <FormDataConsumer>
        {({ formData }) => (
          <NotesField
            label="Notes"
            source="description"
            validate={required('Notes are required.')}
            activityType={formData.type}
          />
        )}
      </FormDataConsumer>
    </CreateInDialogButton>
  );
};
