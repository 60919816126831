import { cn } from '@/modules/ui/utils/cn';
import { Archive } from 'lucide-react';
import React, { cloneElement } from 'react';

export default function EmptyDatagrid({
  resourceName,
  textFormatString = 'No %s found',
  icon = <Archive />,
  description = null,
  actionButton = null,
  classNameEmpty = null,
}) {
  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center space-y-2 p-2',
        classNameEmpty,
      )}
    >
      {cloneElement(icon, {
        className: 'mr-2 text-gray-400 opacity-70',
        size: 64,
        strokeWidth: 1,
      })}
      <div className="text-center">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {textFormatString.replace('%s', resourceName)}
        </h3>
        {description && (
          <p className="mt-1 text-sm text-gray-500 max-w-lg">{description}</p>
        )}
      </div>
      {actionButton && actionButton}
    </div>
  );
}
